import React from 'react'
import { Col, Row,Modal, Spinner} from 'react-bootstrap'
import Slider from '../slider/Slider'
import {IoSettingsOutline} from 'react-icons/io5'
import {AiOutlineSend} from 'react-icons/ai'

function TxHandlingModal(props) {
  const [showSlider, setShowSlider] = React.useState(false);
  const [customFee, setCustomFee] = React.useState(0);
  // console.log(props)
  // console.log(Math.round(props.minReasonableTxFee * 1000) / 1000) 
  // console.log(props.maxAffordableTxFee)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={props.isMobile ? {maxWidth:'360px'} : null }
      className="rounded-modal"
    >
      <Modal.Header closeButton className="custom-close-button">
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
          Creating your team
          <span>
            {/* <Spinner animation="border" style={{borderWidth: '4px', height: '1.5rem', width: '1.5rem'}}/> */}
          </span>
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
          <Row className='justify-content-center'>
            <Col md={10} style={{minWidth: '300px'}}>
                <div style={{fontStyle:'italic', paddingBottom: '1%'}}>
                  Creating a team requires ETH in your wallet to pay for blockchain transaction fees.
                </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md={10} style={{minWidth: '300px',paddingBottom: '1%'}}>
                <div>
                Based on network congestion and gas prices, the transaction fee for creating a team is currently approximately <span style={{fontWeight: 'bold'}}>{Math.round(props.txPrice * 1000) / 1000}</span> ETH.
                You currently have <span style={{fontWeight: 'bold'}}>{Math.round(props.accountEthBalance * 1000) / 1000}</span> ETH in your wallet.
                </div>
            </Col>
          </Row>
          {props.maxAffordableFee >= 1000000000 &&(
          <>
          <Row className='justify-content-center'>
            <Col md={10} style={{minWidth: '300px'}}>
                <div>
                The more you are willing to pay in transaction fees, the faster your transaction will be processed.
                Select the transaction fee you are willing to pay below.
                </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md={5} >
                <button className='button-1' onClick={() => handleButtonClick("min")}>
                  Max I can afford
                </button>
            </Col>
            <Col md={4} >
                <button className='button-1' onClick={() => handleButtonClick("custom")}>
                 <IoSettingsOutline style={{paddingRight: '2%'}}/> Custom Fee
                </button>
            </Col>
          </Row>
          {showSlider && (
            <>
             <Row className='justify-content-center'>
              <Col md={10} style={{minWidth: '300px', paddingTop: '1%'}}>
                <Slider
                  min={Math.round(props.minReasonableTxFee * 1000) / 1000}
                  max={props.maxAffordableTxFee > 0 ? props.maxAffordableTxFee : props.accountEthBalance}
                  saveParams={saveParams}
                  isMobile={props.isMobile}
                />
              </Col>
             </Row>
             <Row className='justify-content-center'>
              <Col md="auto" >
                <button className='button-1' onClick={() => sendCustomTeamTx()}>
                  Send Transaction <AiOutlineSend style={{paddingLeft: '2%'}}/>
                </button>
              </Col>
             </Row>
             </>
          )}
          </>
          )}
          {props.maxAffordableFee <= 1000000000 && ( //12000000000
            <>
            <Row className='justify-content-center'>
                Your balance is too low. Please top up wallet to continue:
            </Row>
            <Row className='justify-content-center'>
               <div style={{fontStyle: 'italic', paddingTop: '2%'}}>
               {props.account}
               </div>
            </Row>
            </>
          )} 
      </Modal.Body>
      
    </Modal>
  );

  function handleButtonClick(type) {
    if (type === "min") {
      props.sendCustomTeamTx()
    } else if (type === "custom") {
      setShowSlider(true)
    }
  }

  function sendCustomTeamTx(){
    if (customFee > 0){
      props.sendCustomTeamTx(customFee)
    }
  }

  function saveParams(value){
    // console.log(value)
    setCustomFee(value)
  }

}

export default TxHandlingModal;
