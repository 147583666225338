import {React, useState, useCallback} from "react";
// Components
import {Spinner} from 'react-bootstrap';
import DownloadWalletModal from './modal/DownloadWalletModal';
// login 
import * as login from '../helpers/login';


function SignInButton({loginButtonFallback,customText,isMobile}) {
    const [signer, setSigner] = useState(undefined)
    const [loginLoading, setLoginLoading] = useState(false)
    const [openDownloadMetaMask, setOpenDownloadMetaMask] = useState(false)

    async function signIn(){
        setLoginLoading(true);
        /*
        // WEB AUTH Sign in
        let safeAuthKit = await login.initAuthL2();
        let signer_; let signInResponse
        try{
            signInResponse = await safeAuthKit.signIn();
            signer_ = signInResponse.eoa;
        } catch(e){
            console.log(e)
            setLoginLoading(false)
        }
        // const web3 = new Web3(safeAuthKit.getProvider());
        */
        // WEB3 Sign in
        let accounts = await login.connectWeb3Wallet()
        let signer_ = accounts[0]
        let web3 = await login.loadWeb3Provider();
        if (web3 == undefined){
            setLoginLoading(false)
            setOpenDownloadMetaMask(true)
        }
        // const network = await web3.eth.net.getId();
        // if (network !== 8453){
        //   console.log("switching to chain")
        //   await switchToChain(web3, "0x2105");
        // }
        // STATE UPDATE
        await login.accountChangeListener(accountListenerFallback)
        setSigner(signer_)
        setLoginLoading(false);
        // Fallback to main page
        let loginData = prepLoginData(signer_,undefined,[],[],web3)
        loginButtonFallback(loginData)
    }

    const accountListenerFallback = useCallback(async (callbackData) => {
        let loginData = prepLoginData(callbackData.signer,undefined,[],[],undefined);
        loginButtonFallback(loginData)
    }, []);

    return (
        <div style={{width: "100%"}}>
            <button className="gradient-button" style={{color: "black"}} onClick={() => signIn()}>
            {!loginLoading ? (
                <div
                  className="sub-title-mobile"
                > 
                  {customText} 
                </div>
            ):(
                <Spinner animation="border" variant="dark" />
            )}
            </button>
            <DownloadWalletModal
                show={openDownloadMetaMask}
                onHide={() => setOpenDownloadMetaMask(false)}
            />
        </div>
    );


    function prepLoginData(signer,selectedSafe,ownedSafes,ownedSafesData,web3){
        // console.log(signer,selectedSafe,ownedSafes,ownedSafesData,web3)
        let loginData = {}
        loginData.signer = signer;
        loginData.selectedSafe = selectedSafe;
        loginData.ownedSafes = ownedSafes;
        loginData.ownedSafesData = ownedSafesData;
        loginData.web3 = web3;
        return loginData;
    }

}

    export default SignInButton;