import React from 'react';

const Rectangle = ({ borderColor }) => {
    const style = {
        width: '40px',
        height: '20px',
        borderStyle: 'solid',
        borderWidth: '5px',
        borderColor: borderColor
    };

    return <div style={style} />;
};

export default Rectangle;
