import React from 'react';
import { Col,Row } from 'react-bootstrap';
import {generateDate} from '../../helpers/utils.js';
import PitchComponent from '../pitch/PitchComponent.js';
import LeagueTable from '../leagues/LeagueTable.js';
import { MdOutlineStadium, MdCalendarMonth} from "react-icons/md";

function GameViewTabs({ 
        homeFormation,
        awayFormation,
        selectedGame, 
        selectedTab,
        teamsData,
        getLeagueData,
        gameResult,
        gameSummary,
        gameStatus,
    }){
    return (
        <div>
            {selectedTab === 'info' && 
            <Info 
                selectedGame={selectedGame}/>
            }
            {selectedTab === 'summary' && 
            <Summary 
                gameResult={gameResult}
                gameSummary={gameSummary}
                gameStatus={gameStatus}
            />}
            {selectedTab === 'stats' && <Stats />}
            {selectedTab === 'lineups' && 
            <Lineups 
                homeFormation={homeFormation}
                awayFormation={awayFormation}
            />}
            {selectedTab === 'table' && 
            <div style={{padding: "1%"}}>
                <Table 
                    teamsData={teamsData}
                    getLeagueData={getLeagueData}
                />
            </div>
            }
            {selectedTab === 'h2h' && <H2h />}
        </div>
    );
};

const Info = ({selectedGame}) => {
    return <div style={{minHeight:'400px'}}>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
            Match Info
            </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <Col md="auto" >
                <div className='d-flex justify-content-center align-items-center' style={{fontFamily: 'Roboto',letterSpacing: '1px'}}>
                    <MdCalendarMonth/>
                    <span >
                        {generateDate(selectedGame.game_start_time)}
                    </span>
                </div> 
            </Col>
            <Col md="auto">
                <div className='d-flex justify-content-center align-items-center' style={{fontFamily: 'Roboto', letterSpacing: '1px'}}>
                    <MdOutlineStadium/>
                    <span>    
                        Base Blockchain
                    </span> 
                </div>
            </Col>
        </Row>
        
    </div>
};

const Summary = ({
    gameResult,
    gameSummary,
    gameStatus
}) => {
    // console.log(gameSummary, gameResult,gameStatus)
    return <div style={{minHeight:'400px'}}>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
            Summary
            </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <Col md={10} >
                <div style={{fontFamily: 'Roboto', letterSpacing: '1px'}} className='d-flex justify-content-center align-items-center'>
                {gameStatus.gameTime < 90 ? 'No summary available yet.' : gameSummary}
                </div>
            </Col>
        </Row>
    </div>
};

const Stats = () => {
    return <div style={{minHeight:'250px'}}>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
            Stats
            </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', letterSpacing: '1px'}}>
            No stats available.
            </div>
        </Row>
    </div>
};

const Lineups = ({ 
    homeFormation,
    awayFormation,
}) => {
    // console.log(homeFormation, awayFormation)
    return <div style={{minHeight:'600px'}}>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
            Lineups
            </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', letterSpacing: '1px'}}>
            <PitchComponent
                homeFormation={homeFormation}
                awayFormation={awayFormation}
                isMobile={false}
            />
            </div>
        </Row>
    </div>
};

const Table = ({teamsData,getLeagueData}) => {
    return <div style={{minHeight:'250px'}}>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
            Table
            </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <LeagueTable
                leagueId={1}
                teamsData = {teamsData}
                getLeagueData = {getLeagueData}
            />
        </Row>
    </div>
};

const H2h = () => {
    return <div style={{height:'250px'}}>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', fontWeight:'bold', letterSpacing: '1px'}}>
            H2H
            </div>
        </Row>
        <Row className="justify-content-center" style={{ paddingTop: "4%"}}>
            <div style={{fontFamily: 'Roboto', letterSpacing: '1px'}}>
            No previous matches.
            </div>
        </Row>
    </div>
};


export default GameViewTabs;
