import React from 'react'
import { Col, Row,Modal, Spinner} from 'react-bootstrap'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import {IoIosCheckmarkCircleOutline} from 'react-icons/io'
import {AiFillHeart} from 'react-icons/ai'

function TxModal(props) {
  // console.log(props)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={props.isMobile ? {maxWidth:'360px'} : null }
      className="rounded-modal"
    >
      <Modal.Header closeButton className="custom-close-button">
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
          Waiting for transactions...
          <span>
            <Spinner animation="border" style={{borderWidth: '4px', height: '1.5rem', width: '1.5rem'}}/>
          </span>
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <Row className='justify-content-center'>
          <Row className='justify-content-center'>
            <Col md={1}>
            {props.txStatus.currentStep >= 1 ? (
              <IoIosCheckmarkCircleOutline
              style={{color: 'green'}}
              />
            ) : (
              <BsFillArrowRightCircleFill
                style={{color: 'black'}}
              />
            )}
            </Col>
            <Col md={11} style={{minWidth: '300px', maxWidth: '315px'}}>
                <div>
                  1) Confirm the transaction in your wallet.
                </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md={1}>
            {props.txStatus.currentStep >= 2 && (
              <IoIosCheckmarkCircleOutline/>
            )}
            {props.txStatus.currentStep === 1 && (
              <BsFillArrowRightCircleFill
                style={{color: 'black'}}
              />
            )}
            </Col>
            <Col md={11} style={{minWidth: '300px', maxWidth: '315px'}}>
                <div style={{minWidth: '300px'}}>
                  2) Wait for the transaction confirmation.
                </div>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md={1}>
            {props.txStatus.currentStep >= 3 && (
              <IoIosCheckmarkCircleOutline/>
            )}
            {props.txStatus.currentStep === 2 && (
              <AiFillHeart
                style={{color: 'red'}}
              />
            )}
            </Col>
            <Col md={11} style={{minWidth: '300px', maxWidth: '315px'}}>
              <div style={{minWidth: '300px'}}>
                3) Start playing!
              </div>
            </Col>
          </Row>
        </Row>
      </Modal.Body>
      
    </Modal>
  );
}

export default TxModal;
