import React from 'react';

function PitchLines() {

    const cornerCircleStyle = (top, left) => ({
        position: 'absolute',
        width: '20px',  // Size of the corner circle
        height: '20px', // Size of the corner circle
        backgroundColor: 'transparent',
        borderRadius: '50%',
        border: '2px solid white',
        top: top === 'top' ? '-10px' : '96%',
        left: left === 'left' ? '-10px' : '98%',
        clipPath: top === 'top' 
                  ? (left === 'left' ? 'polygon(100% 100%, 0 100%, 100% 0)' : 'polygon(0 100%, 0 0, 100% 100%)')
                  : (left === 'left' ? 'polygon(100% 0, 100% 100%, 0 0)' : 'polygon(0 0, 100% 0, 0 100%)'),
        zIndex: 1
    });

    const lineStyle = {
        position: 'absolute',
        left: '50%',
        top: '0',
        bottom: '0',
        width: '2px',
        backgroundColor: 'transparent',
        border: '2px solid white',
        transform: 'translateX(-50%)'
    };
    
    const circleStyle = {
        position: 'absolute',
        width: '100px', // Size of the circle
        height: '100px', // Size of the circle
        backgroundColor: 'transparent',
        borderRadius: '50%',
        border: '2px solid white',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    };

    const goalAreaBoxStyle = (side) => ({
        position: 'absolute',
        width: '10%', // Width of 10% of the parent box
        height: '40%', // Height of 20% of the parent box
        backgroundColor: 'transparent', // Transparent background
        border: '2px solid white', // White border
        [side === 'left' ? 'borderLeft' : 'borderRight']: 'none', // Remove the border on the side facing the border of the main box
        top: '30%', // Positioned such that it extends ±10% from the center
        [side]: '0' 
    });

    const goalBoxStyle = (side) => ({
        position: 'absolute',
        width: '4%', // Width of 10% of the parent box
        height: '20%', // Height of 20% of the parent box
        backgroundColor: 'transparent', // Transparent background
        border: '2px solid white', // White border
        [side === 'left' ? 'borderLeft' : 'borderRight']: 'none', // Remove the border on the side facing the border of the main box
        top: '40%', // Positioned such that it extends ±10% from the center
        [side]: '0' 
    });

    return (
        <React.Fragment>
            <div style={lineStyle}></div>
            <div style={circleStyle}></div>
            <div style={goalAreaBoxStyle('left')}></div> {/* Left box */}
            <div style={goalAreaBoxStyle('right')}></div> {/* Right box */}
            <div style={goalBoxStyle('left')}></div> {/* Left box */}
            <div style={goalBoxStyle('right')}></div> {/* Right box */}
            <div style={cornerCircleStyle('top', 'left')}></div>    {/* Top Left Corner */}
            <div style={cornerCircleStyle('top', 'right')}></div>   {/* Top Right Corner */}
            <div style={cornerCircleStyle('bottom', 'left')}></div> {/* Bottom Left Corner */}
            <div style={cornerCircleStyle('bottom', 'right')}></div> {/* Bottom Right Corner */}
        </React.Fragment>
    );
}

export default PitchLines;
