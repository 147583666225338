import {React,useState} from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as IoIcons from 'react-icons/io';
import * as Io5Icons from 'react-icons/io5';
import {FaTelegram} from 'react-icons/fa';
import {FaSquareXTwitter,FaSquareInstagram,FaShareNodes} from 'react-icons/fa6';

const CouponCodeBox = ({ code, isMobile }) => {
    const [couponCopied, setCouponCopied] = useState(false)

    function handleCopy(){
        setCouponCopied(true)
        setTimeout(() => {
            setCouponCopied(false);
          }, 2000);
    }

    const shareOnTwitter = () => {
        // Replace the URL and text as needed
        const twitterUrl = `https://twitter.com/intent/tweet?text=${'Joining the Chainballers league ' + encodeURIComponent(code)}&url=https://chainballers.xyz`;
        window.open(twitterUrl, '_blank');
      };
    
      const shareOnInstagram = () => {
        // Replace the URL and text as needed
        const instagramUrl = `https://www.instagram.com/?caption=${encodeURIComponent(code)}`;
        window.open(instagramUrl, '_blank');
      };
    
      const shareOnDiscord = () => {
        // Replace the URL and text as needed
        const discordUrl = `https://discord.com/channels/123456789012345678/123456789012345678?text=${encodeURIComponent(code)}`;
        window.open(discordUrl, '_blank');
      };
    
      const shareOnTelegram = () => {
        // Replace the URL and text as needed
        const telegramUrl = `https://t.me/share/url?url=https://chainballers.xyz&text=${encodeURIComponent(code)}`;
        window.open(telegramUrl, '_blank');
      };

    return (
        <div className={!isMobile ? "coupon-code-box":"coupon-code-box-mobile"}>
            <div className="coupon-code-header">
                <h2>Your Coupon Code</h2>
            </div>
            <div className="coupon-code">
                <span className="coupon-code-text">{code != undefined ? code.substring(0, 10): null}</span>
                <CopyToClipboard text={code} onCopy={() => handleCopy()}>
                    <span style={{cursor: "pointer"}}>
                    { 
                        couponCopied ? <IoIcons.IoIosCheckmark/> : <IoIcons.IoMdCopy/>
                    }
                    </span>
                </CopyToClipboard>
            </div>
            <div style={{paddingTop: "5%"}}>
                Share on
            </div>
            <div>
                <button className="coupon-code-buttons" onClick={shareOnTwitter}>
                    Twitter
                    <FaSquareXTwitter/>
                </button>
                <button className="coupon-code-buttons" onClick={shareOnInstagram}>
                    Instagram
                    <FaSquareInstagram/>
                </button>
                <button className="coupon-code-buttons" onClick={shareOnDiscord}>
                    <>
                    Discord
                    <Io5Icons.IoLogoDiscord/>
                    </>
                </button>
                <button className="coupon-code-buttons" onClick={shareOnTelegram}>
                    Telegram
                    <FaTelegram/>
                </button>
            </div>
        </div>
    );
};

export default CouponCodeBox;
