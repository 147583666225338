import React, { Component } from 'react';
import { Col, Spinner, Row } from 'react-bootstrap';
import TxGeneralModal from '../components/modal/TxGeneralModal';
import {AiOutlineSend} from 'react-icons/ai';


class Referral extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  
  async componentDidMount(){
    this.props.referralCallback();
  }

  handleSponsorClick(){
    this.setState({
      sponsorClicked: true
    })
  }
  
  setModalShow(bool){
    this.setState({
      modalShow: bool
    })  
  }
  
  async handleCreateTeamClick(){
    this.setState({modalShow: true, txStatus: "pending", txStatusMsg: "Creating Team..."})
    try{
      await this.props.createSponsoredTeam(this.state.sponsoredAddress)
      this.setState({txStatus: "success", txStatusMsg: "Team Created!"})
    } catch (error) {
      this.setState({txStatus: "error", txStatusMsg: "Error Creating Team"})
    }
  }

  handleOnChange = async (e) => {
    // console.log(e.target.value)
    this.setState({
      sponsoredAddress: e.target.value
    })
  }

  render(){
    return (
      <div style={ window.innerWidth > 1000 ? {paddingTop: "1%", paddingLeft: "7%"} : {paddingTop: "5%",paddingLeft: "2%"}}>
      <Row className="justify-content-center">
        <span className='table-title'>
          Referral Program
        </span>
      </Row>
      <Row className="justify-content-center">
        <Col md="auto">
          <div className='sub-title'>
            Invite your friends to play Chainballers
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="auto">
          <div className='sub-title'>
           1 friend = 1 free player!
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <button className='button-1' onClick={() => this.handleSponsorClick()}>
          Sponsor a friend
        </button>
      </Row>
      {this.state.sponsorClicked && (
        <>
        <Row className="justify-content-center">
          <Col md="auto">
            <div className='table-title'>
              Your Address
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{paddingBottom: "1%"}}>
          <Col md="auto">
            <input className="sponsor-input" type="text" name="search" id="search" placeholder="Your Address" 
                  value={this.props.account}
                  disabled={true}
            />
          </Col>
        </Row>
        <Row className="justify-content-center" style={{paddingBottom: "2%"}}>
          <Col md="auto">
            <div >
              Your Chainballer player reward will be received at this address.
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="auto">
            <div className='table-title'>
              Sponsored Address
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="auto">
            <input className="sponsor-input" type="text" name="search" id="search" placeholder="Enter your friend's address" 
                  onChange={(event) => { this.handleOnChange(event)
                  }}
                  ref={(input) => { this.input = input }}
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <button className='button-1' onClick={() => this.handleCreateTeamClick()}>
            Create the Team! <AiOutlineSend style={{paddingLeft: '2%'}}/>
          </button>
        </Row>
        </>
      )}
      <TxGeneralModal
        show={this.state.modalShow}
        status={this.state.txStatus}
        statusMsg={this.state.txStatusMsg}
        onHide={() => this.setModalShow(false)}
      />
      </div>
    );
  }
}

export default Referral;
