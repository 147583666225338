import React, { useState, useEffect } from 'react';
import { PiLightningDuotone } from "react-icons/pi";
import { Row, Col, Container } from 'react-bootstrap';
import { svgTextToImage, replaceSvgBackgroundColorWithTransparencyLong } from '../../../helpers/players.js';
import './style/LeagueSelectionCard.css';

function BaseLeague({ data, createTeam, onLeagueSelect, isSelected, isMobile }) {
    const [svgImage, setSvgImage] = useState(null);
    const [isClicked, setIsClicked] = useState(false);
    const [showHiddenText, setShowHiddenText] = useState(false);

  useEffect(() => {
    async function loadSvgAsText() {
      try {
        const response = await fetch('./images/faces/base-league-face.svg');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const svgText = await response.text();
        return svgText;
      } catch (error) {
        console.error('Error fetching the SVG:', error);
      }
    }

    loadSvgAsText().then(svgText => {
      const modifiedSvgText = replaceSvgBackgroundColorWithTransparencyLong(svgText, "#ffffff");
      const imageElement = svgTextToImage(modifiedSvgText);
      setSvgImage(imageElement); // Update state with the Image element
    });
  }, []);

  const fixedButtonRowStyle = {
    position: 'absolute',
    bottom: '5%',
    width: '100%',
    textAlign: 'center',
    zIndex: 3,
  };

  const containerStyle = {
    position: 'relative',
    height: '100%',
    paddingTop: '5%',
  };

  const handleCardClick = (e) => {
    if (e.target.closest('button')) return;
    onLeagueSelect();
    if (!isSelected) {
        setIsClicked(false);
    }
    // setIsSelected(!isSelected);
  };

  const handleButtonClick = (e) => {
    onLeagueSelect();
    setIsClicked(true);
    e.stopPropagation();
    createTeam();
  };

  return (
    <div className={`first ${isSelected ? 'card-selected' : ''}`} onClick={handleCardClick}>
      <div className="base">
        <div className="gradient-overlay"></div>
        <img src="./images/base-league.png" alt="Base League" className="image-style" />
        <Container
          style={containerStyle}
          onMouseEnter={() => setShowHiddenText(true)}
          onMouseLeave={() => setShowHiddenText(false)}
        >
          <Row className="justify-content-center">
            <Col className='text-center z-index-3 '>
              <div className='sub-title' style={{ lineHeight: "1.4" }}>Base</div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className='text-center z-index-3 '>
              <div className='sub-title' style={{ fontSize: "28pt", lineHeight: "1.2" }}>League</div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className='text-center z-index-3 '>
              <div className='sub-title-style sub-title'>
                powered by
                <img src="./images/logos/base-logo.svg"
                  style={{ width: '60px', height: '30px', marginLeft: '0px', marginRight: '5px', marginTop: "5px" }} alt='base_logo' />
              </div>
            </Col>
          </Row>

          {!showHiddenText ? (
            <Row className="justify-content-center">
             <Col className='text-center z-index-3 '>
                <img className="img-style" src={svgImage} alt="Base League Face" />
              </Col>
            </Row>
          ) : (
            <>
              <Row className="justify-content-center" style={{ paddingTop: "4%" }}>
                <Col className='text-center z-index-3 '>
                  <div className='hidden-text-style sub-title hidden-on-load'>
                    Number of Teams
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3 '>
                  <div className='large-hidden-text-style sub-title hidden-on-load'>
                    {data.numberOfTeams}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3 '>
                  <div className='hidden-text-style sub-title hidden-on-load'>
                    Total Value
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3 '>
                  <div className='large-hidden-text-style sub-title hidden-on-load'>
                    ${data.totalValue}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3 '>
                  <div className='hidden-text-style sub-title hidden-on-load'>
                    Average Team Cost
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className='text-center z-index-3 '>
                  <div className='large-hidden-text-style sub-title hidden-on-load'>
                    ${data.averageTeamCost}
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row style={fixedButtonRowStyle}>
            <Col>
              <button className={`button-style-1 ${isClicked ? 'card-selected' : ''}`} onClick={handleButtonClick}>
                Create Team
                <PiLightningDuotone style={{ color: 'black', secondaryColor: "yellow", marginRight: '5px' }} />
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default BaseLeague;
