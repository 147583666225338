const gameResults = {
    1: "0 - 3", 
    2: "0 - 0", 
    3: "1 - 0", 
    4: "0 - 1", 
    9: "0 - 2",
    11: "0 - 1",
    12: "2 - 0",
    13: "2 - 1",
    17: "2 - 1",
    18: "2 - 1",
    20: "0 - 0",
    21: "3 - 2",
    25: "3 - 3", 
    26: "2 - 1", 
    29: "3 - 1",
    30: "0 - 1",
    33: "0 - 1", 
    34: "2 - 0",
    35: "2 - 2",
    38: "1 - 1",
    41: "0 - 1",
    42: "3 - 3",
    43: "2 - 1",
    47: "2 - 0",
    // 49: "2 - 1",
    50: "1 - 1",
    51: "0 - 1",
    52: "1 - 1",
};


export default gameResults;