import React from 'react'
import { Col, Row,Spinner,Modal} from 'react-bootstrap'

function TxGeneralModal(props) {

  return (
    <Modal
      {...props}
      size={props.isMobile ? "sm" : "md"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="rounded-modal"
      style={props.isMobile ? {maxWidth:'390px'} : null }
    >
      <Modal.Header closeButton className="custom-close-button">
        <>
        {props.status === "success" && (
            <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
                Success! 
            </Modal.Title>
        )}
        {props.status !== "success" && (
            <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
                Waiting for transaction... 
                <span>
                  <Spinner animation="border" style={{borderWidth: '4px', height: '1.5rem', width: '1.5rem'}}/>
                </span>
            </Modal.Title>
        )}
        </>
      </Modal.Header>
      <Modal.Body>
        <Row className='justify-content-center' style={{paddingBottom:"2%"}}>
            <Col md={11}>
            {props.status !== "success" && (
                <div>
                  Confirm the transaction in your wallet.
                </div> 
            )}
            {props.status === "success" && (
                <div>
                  {props.statusMsg}
                </div> 
            )}
            </Col>
        </Row>
      </Modal.Body>
      
    </Modal>
  );
}

export default TxGeneralModal;
