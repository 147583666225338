import React from 'react'
import { Col, Row,Modal} from 'react-bootstrap'
import * as login from '../../helpers/login.js'

function DownloadWalletModal(props) {
  // console.log(props)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="rounded-modal"
    >
      <Modal.Header closeButton className="custom-close-button">
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
          No wallet detected
        </Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <Row className='justify-content-center'>
            <Row className='justify-content-center'>
                <Col md={1}>
                </Col>
                <Col md={11} style={{minWidth: '300px', maxWidth: '315px'}}>
                    <div>
                    1) Download a wallet extension like Metamask or Coinbase Wallet.
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col md={1}>
                </Col>
                <Col md={11} style={{minWidth: '300px', maxWidth: '315px'}}>
                    <div style={{minWidth: '300px'}}>
                    2) Refresh the page.
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-center'>
                <Col md={11} style={{minWidth: '300px', maxWidth: '315px'}}>
                    <button className='button-1' onClick={() => login.handleDownloadMetaMask()}>
                        <div style={{minWidth: '250px'}}>
                            Download Wallet <img src="./mm-logo.svg" style={{height: '20px', width: '20px'}}/>
                        </div>
                    </button>
                </Col>
            </Row>
        </Row>
      </Modal.Body>
      
    </Modal>
  );
}

export default DownloadWalletModal;
