import React from 'react';
import { Modal, Row, Col, Figure, Button } from 'react-bootstrap';
import PlayerCardSingle from '../nft/PlayerCardSingle';
import SkillBar from '../skillBar/SkillBar';

// helpers
import * as players from '../../helpers/players.js';

function PlayerModal({ show, onHide, playerData, isMobile}) {
  // Extract necessary data from playerData, with default values if playerData is undefined
  const skills = playerData ? [
    { name: 'Defending', value: playerData.defending },
    { name: 'Goalkeeping', value: playerData.goalkeeping },
    { name: 'Passing', value: playerData.passing },
    { name: 'Playmaking', value: playerData.playmaking },
    { name: 'Scoring', value: playerData.scoring },
    { name: 'Set Pieces', value: playerData.set_pieces },
    { name: 'Stamina', value: playerData.stamina },
    { name: 'Winger', value: playerData.winger }
  ] : [];

  return (
    <Modal show={show} onHide={onHide} centered size="lg"
    aria-labelledby="contained-modal-title-vcenter"
        className="rounded-modal"
        style={isMobile ? {maxWidth:'390px'} : null }
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Baller #${playerData?.baller_id || 'Unknown'} Details`}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ padding: '4%', paddingBottom: '6%' }}
      >
        <Row>
          <Col md={5} className="d-flex justify-content-center">
            <Figure>
              <PlayerCardSingle
                cardData={{
                  tokenId: undefined,
                  imgSrc: playerData ? players.uriToImage(playerData.baller_uri) : '', 
                  title: `Baller #${playerData?.baller_id || 'Unknown'}`
                }}
                cardSelection={playerData?.baller_id || 0}
                cardFunction={() => {}}
              />
            </Figure>
          </Col>
          <Col md={7} sm={12}
            style={{paddingRight: '6%'}}
          >
              <>
                <Row style={{ paddingTop: '0%' }}>
                  <table style={{ width: '100%'}}>
                    <thead>
                      <tr>
                        <th >Attribute</th>
                        <th style={{textAlign: 'center'}}>Level</th>
                        {/* <th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {skills.map((skill, index) => (
                        <tr key={index} >
                          <td>{skill.name}</td>
                          <td style={{width: "40%"}}>
                            <SkillBar
                              skillValue={skill.value}
                              skillName={skill.name}
                            />
                          </td>
                          <td style={{textAlign: 'center'}}>
                            <div 
                              className="sub-title-mobile"
                            >
                              {skill.value}
                            </div>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Row>
              </>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PlayerModal;
