import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

const PaymentForm = ({ onSuccess }) => {
  const stripe = useStripe(); // Initialize Stripe
  const elements = useElements(); // Initialize Elements
  const [loading, setLoading] = useState(false); // State to handle loading status
  const [errorMessage, setErrorMessage] = useState(null); // State to handle errors

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setLoading(true); 
    setErrorMessage(null); // Reset error message

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Optional: Redirect or display success page
        // return_url: 'https://your-website.com/payment-success', // Replace with your actual success URL
      },
      redirect: 'if_required', // Redirect if 3D Secure or additional confirmation is needed
    });

    if (error) {
        // Show error to your customer (e.g., insufficient funds)
        setErrorMessage(error.message);
    } else {
        // Payment has succeeded, handle post-payment logic here
        console.log('Payment successful');
        onSuccess(); 
    }

    setLoading(false); // Reset loading state
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {errorMessage && <div style={{ color: 'red', marginTop: '10px' }}>{errorMessage}</div>}
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <button className='button-1' type="submit" disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'Buy now'}
        </button>
      </div>
    </form>
  );
};

export default PaymentForm;
