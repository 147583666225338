

export function calcPositionScore(formationType, position, player){
    let positionObj = {}
    // console.log(formationType, position, player)
    let positionDes = getPositionType(formationType,position)
    let score = _calcPositionScore(positionDes,player)
    // console.log(positionDes, score)
    let colorScore = _convertScoreToColor(score);
    positionObj.description = positionDes;
    positionObj.positionScore = score;
    positionObj.scoreColor = colorScore;
    return positionObj;
}

function getPositionType(formation, position) {
    if (position === 0) {
        return 'GK';
    }
    let currentPosition = 0;
    
    // Define position types for each line
    const positionTypes = [
        ['LWD', 'LCD', 'CD', 'RCD', 'RWD'], // Defense
        ['LWM', 'LCM', 'CM', 'RCM', 'RWM'], // Midfield
        ['LWF', 'LCF', 'CF', 'RCF', 'RWF']  // Forward
    ];

    // console.log(formation, position, positionTypes)
    if (formation !== undefined){
        for (let i = 0; i < formation.length; i++) {
            currentPosition += formation[i];
            if (position <= currentPosition) {
                // Calculate the position within the line
                const linePosition = position - (currentPosition - formation[i]);
                let adjustedLinePosition;
                switch (formation[i]) {
                    case 5:
                        adjustedLinePosition = linePosition - 1; // 0, 1, 2, 3, 4
                        break;
                    case 4:
                        if (linePosition <= 2) {
                            adjustedLinePosition = linePosition - 1;
                        } else {
                            adjustedLinePosition = linePosition; // Skips index 2
                        }
                        break;
                    case 3:
                        adjustedLinePosition = linePosition; // 1, 2, 3
                        break;
                    case 2:
                        adjustedLinePosition = linePosition === 1 ? 1 : 3; // 1, 3
                        break;
                    case 1:
                        adjustedLinePosition = 2; // 2 (central position)
                        break;
                    default:
                        return 'Invalid formation';
                }
                return positionTypes[i][adjustedLinePosition];
            }
        }
    }

    return 'Invalid position number';
}



function _calcPositionScore(positionDes, ballerData){
    let playerSkills = ballerData.skillsName.reduce((result, skillName, index) => {
        result[skillName] = ballerData.skillsValue[index];
        return result;
    }, {});

    // Define skill weights for each position
    const skillWeights = {
        'GK': { 'Goalkeeping': 0.7, 'Defending': 0.3 },
        'LCD': { 'Defending': 0.8, 'Playmaking': 0.2},
        'RCD': { 'Defending': 0.8, 'Playmaking': 0.2 },
        'CD': { 'Defending': 0.8, 'Playmaking': 0.2 },
        'LWD': { 'Defending': 0.6, 'Winger': 0.3, 'Playmaking': 0.1 },
        'RWD': { 'Defending': 0.6, 'Winger': 0.3, 'Playmaking': 0.1 },
        'CM': { 'Playmaking': 0.5, 'Passing': 0.3, 'Scoring': 0.2 },
        'LCM': { 'Defending': 0.4, 'Passing': 0.35, 'Playmaking': 0.25 },
        'RCM': { 'Defending': 0.4, 'Passing': 0.35, 'Playmaking': 0.25 },
        'LWM': { 'Winger': 0.5, 'Passing': 0.3, 'Defending': 0.2 },
        'RWM': { 'Winger': 0.5, 'Passing': 0.3, 'Defending': 0.2 },
        'LCF': { 'Scoring': 0.6, 'Passing': 0.25, 'Winger': 0.15 },
        'RCF': { 'Scoring': 0.6, 'Passing': 0.25, 'Winger': 0.15 },
        'CF': { 'Scoring': 0.6, 'Passing': 0.25, 'Winger': 0.15 },
    };

    let score = 0;

    // Check if the position description is valid
    if (!skillWeights[positionDes]) {
        return 'Invalid position description';
    }

    // Calculate the score based on player skills and position skill weights
    for (const skill in skillWeights[positionDes]) {
        if (playerSkills[skill]) {
            score += playerSkills[skill] * skillWeights[positionDes][skill];
        }
    }
    let scaledScore = score ;
    // Normalize the score to a scale of 1 to 100
    return Math.min(Math.max(Math.round(scaledScore), 1), 100);
}


export function _convertScoreToColor(numScore) {
    // Ensure the score is within the expected range
    numScore = Math.max(0, Math.min(numScore, 100));

    // Define color based on the score
    if (numScore > 70) {
        return 'rgb(0, 255, 0)'; // Green
    } else if (numScore >= 40 && numScore <= 69) {
        return 'rgb(255, 255, 0)'; // Yellow
    } else if (numScore >= 15 && numScore < 40) {
        return 'rgb(255, 165, 0)'; // Orange
    } else {
        return 'rgb(255, 0, 0)'; // Red
    }
}
