import {React, useEffect, useState, useRef} from 'react';
import {Card } from 'react-bootstrap';
import VanillaTilt from 'vanilla-tilt';

import * as players from '../../helpers/players.js';

function PlayerSlimCard({ cardData, cardSelection }) {
  const tiltRef = useRef();
  const [modifiedCardData, setModifiedCardData] = useState(cardData);

  useEffect(() => {
    // Initialize VanillaTilt on the DOM element using the ref
    VanillaTilt.init(tiltRef.current, {
      reverse: true, 
      max: 25, // Maximum tilt angle
      speed: 400, // Tilt speed
      glare: true, // Enable glare effect
      startY: -50,
      'max-glare': 0.5 // Max glare opacity
    });

    // Replace color with transparency
    setModifiedCardData(players.replaceItemColorWithTransparency(cardData, "#ffffff"));

  }, [cardData]);

  return (
    <Card ref={tiltRef} className="tilt" style={{ boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',padding: '3%' }} >
      <Card.Img variant="top" src={modifiedCardData.imgSrc} />
     {/* <img src={modifiedCardData.imgSrc}/> */}
    </Card>
  );
}

export default PlayerSlimCard;