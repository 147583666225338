import React from 'react';
import { Radar } from 'react-chartjs-2';
import 'chart.js/auto';

const RoseChart = ({playersData}) => {
    const backgroundColors = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
    ];
    const borderColors = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
    ];

    var data = {}
    if (playersData && playersData.length > 0) {
        data = {
            labels: playersData[0].skillsName,
            datasets: playersData
            .filter(player => player !== undefined)
            .map((player, index) => ({
                label: player.title, 
                data: player.skillsValue,
                backgroundColor: backgroundColors[index % backgroundColors.length], 
                borderColor: borderColors[index % borderColors.length], 
                borderWidth: 1
            }))
        };
    }
    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true
                },
                suggestedMin: 0, // Adjust as needed
                suggestedMax: 20, // Adjust as needed
                ticks: {
                    display: false // This will hide the tick labels
                }
            }
        },
        plugins: {
            legend: {
                display: true // Set to false if you also want to hide the legend
            }
        }
    };
    
    return (
        <Radar data={data} options={options} />
    );
};

export default RoseChart;
