import React, { lazy, Suspense, Component } from 'react';
import { Loader,Text} from "@react-three/drei";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { LoadingScreen } from '../components/three-d/loadingScreen/LoadingScreen';
// 3d
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";
import Explore2D from '../components/three-d/Explore2D';
import Village from '../components/three-d/Village';
const Explore3D = lazy(() => import('../components/three-d/Explore3D'));

class Background extends Component {
  constructor(props) {
    super(props)
    this.state = {
      start: false
    }
    this.canvasRef = React.createRef();
  }

  setStart(start) {
    this.setState({
      start: start
    })
  }

  async componentDidMount() {
    const canvas = this.canvasRef.current;
    const renderer = new THREE.WebGLRenderer({ canvas });
    this.renderer = renderer; // Store the renderer reference
    await this.init();
  }

  async init(){
  }

  componentWillUnmount() {
    // Clean up resources when the component is unmounted
    if (this.renderer) {
      // Dispose of the renderer's resources (optional, but recommended)
      this.renderer.dispose();
    }
  }

  render(){
    return (
      <>
      {this.props.isMobile ? <Explore2D/> : (
        <>
        <Canvas ref={this.canvasRef} style={{
          height: "100vh", // Full viewport height
          width: "100vw",  // Full viewport width
          position: 'absolute',
          top: "0",
          left:"0",
          background: "#DFF4F5",
          overflow: 'hidden' // Prevents scrolling issues
        }} shadows camera={{ position: [0, 1, 10], fov: 20 }}>
          <Suspense fallback={null}>
            {<Village/>}
          </Suspense>
        </Canvas>
        {/* <Loader /> */}
        {/* <LoadingScreen started={this.state.start} onStarted={() => this.setStart(true)} /> */}
        </>
        )}
      </>
    );
  }
}

export default Background;


function LoadingFallback() {
  return (
    <Text
    font="fonts/Caprasimo-Regular.ttf"
    fontSize={0.3}
    position={[0, 0, 0.051]}
    anchorY={"center"}
    color="black"
  >
    Loading...
    </Text>
  )
}