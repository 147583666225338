import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Player.scss";


export type Player = {
    image: string | undefined;
    name?: string;
    number?: number;
    color?: string;
    numberColor?: string;
    nameColor?: string;

    onClick?(): void;
}

interface PlayerViewProps {
    player: Player;
}

interface PlayerViewState {

}

class PlayerView extends Component<PlayerViewProps, PlayerViewState> {

    static playerShape = PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        number: PropTypes.number,
        color: PropTypes.string,
        numberColor: PropTypes.string,
        nameColor: PropTypes.string,
        onClick: PropTypes.func
    });

    render() {

        const { player } = this.props;
        // console.log(player)
        return (
            <div className="player-view">
                <div
                    className={ classNames("player", { "clickable": player.onClick }) }
                    style={ { backgroundColor: player.color } }
                    onClick={ player.onClick }
                >
                     <div
                        className="number"
                        style={ { color: player.numberColor } }
                    >{ player.number }</div>

                    <div
                        className="name"
                        style={ { color: player.nameColor } }
                    >{ player.name }</div>

                </div>
                <img src={player.image} alt={player.name} className="player-image" style={{ transform: 'rotate(-90deg)' }} />   
            </div>
        );
    }
}

export default PlayerView;
