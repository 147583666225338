import React from 'react';

const SkillBar = ({ skillValue }) => {
  const getBarGradient = (value) => {
    if (value <= 15) {
        return 'linear-gradient(to right, #ff6f69 0%, #ff3e41 51%, #ff6f69 100%)'; 
    } else if (value <= 40) {
        return 'linear-gradient(62deg, rgb(251, 171, 126) 0%, rgb(247, 206, 104) 100%)'; 
    } else if (value <= 70) {
        return 'linear-gradient(to right, #FAEE46 0%, #DBF688 51%, #A6FA46 100%)';
    } else {
      return 'linear-gradient(62deg, #31AA10 0%, #52c234 100%)';
    }
  };

  const barStyle = {
    height: '20px',
    width: `${skillValue}%`,
    backgroundImage: getBarGradient(skillValue),
    borderRadius: '5px',
    transition: 'width 0.5s ease-in-out'
  };

  return (
    <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: '10px', boxShadow: "0 1px 4px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24)"}}>
      <div style={barStyle}></div>
    </div>
  );
};

export default SkillBar;
