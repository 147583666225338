import React from 'react'
import { Col, Row,Modal} from 'react-bootstrap'
import {AiOutlineSend} from 'react-icons/ai'

function NameChangeModal(props) {
  const [newTeamName, setNewTeamName] = React.useState('');

  // console.log(props)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="rounded-modal"
    >
      <Modal.Header closeButton className="custom-close-button">
        <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
          Change team name
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='justify-content-center' style={{paddingBottom:"2%"}}>
            <Col md={11}>
                <div>
                  Enter a new name for your team.
                </div> 
            </Col>
        </Row>
        <Row className='justify-content-center'>
            <Col md={11}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter new team name"
                  value={newTeamName}
                  onChange={(e) => setNewTeamName(e.target.value)}
                  style={{borderRadius: '10px'}}
                />
            </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md="auto">
            <button className='button-1' onClick={() => handleSaveClick()}>
                <div >
                    Save <AiOutlineSend style={{paddingLeft: '2%'}}/>
                </div>
            </button>
          </Col>
        </Row>
      </Modal.Body>
      
    </Modal>
  );

  async function handleSaveClick(){
    await props.setTeamName(newTeamName)
    props.onHide()
  }
}

export default NameChangeModal;
