export async function findBlockByTimestamp(startBlock, endBlock, targetTimestamp, web3) {
    if (startBlock > endBlock) {
        return -1; // Block not found
    }
    let midBlock = Math.floor((startBlock + endBlock) / 2);
    let block = await web3.eth.getBlock(midBlock);
    // console.log(block)
    if (block.timestamp > targetTimestamp) {
        return findBlockByTimestamp(startBlock, midBlock - 1, targetTimestamp,web3);
    } else if (block.timestamp < targetTimestamp) {
        return findBlockByTimestamp(midBlock + 1, endBlock, targetTimestamp,web3);
    } else {
        return block.number; // Block found
    }
}

export async function searchEvents(startBlock, ContractAddress, topics, web3) {
    const endBlock = await web3.eth.getBlockNumber();
    const batchSize = 2000;
    let eventFound = false;
    let foundEvents = [];
    // console.log(topics)
    async function fetchEvents(fromBlock, web3) {
        if (eventFound) return; // Exit if event is already found

        const toBlock = Math.min(fromBlock + batchSize, endBlock);
        
        await web3.eth.getPastLogs({
            address: ContractAddress,
            topics: topics,
            fromBlock: fromBlock,
            toBlock: toBlock
        })
        .then(res => {
            if (res.length > 0) {
                // console.log(`Event found in blocks ${fromBlock} to ${toBlock}`);
                // console.log(res); // Log the details of the events
                foundEvents = res;
                eventFound = true; // Set the flag to true as event is found
            }
        })
        .catch(err => {
            console.error(`Error fetching logs for blocks ${fromBlock} to ${toBlock}`, err);
        });

        if (!eventFound && toBlock < endBlock) {
            await fetchEvents(toBlock + 1, web3);
        }
    }

    await fetchEvents(startBlock, web3);
    return foundEvents;
}

export function formatToHex(number) {
    // Convert the number to hexadecimal
    let hexNumber = Number(number).toString(16);
    // Pad the hexadecimal number with leading zeros to make it 64 characters long
    const desiredLength = 64;
    hexNumber = hexNumber.padStart(desiredLength, '0');

    // Add the '0x' prefix
    return "0x" + hexNumber;
}

export function hexStringToNumbers(hexString) {
    // Remove the '0x' prefix if present
    if (hexString.startsWith('0x')) {
        hexString = hexString.substring(2);
    }

    // Assuming the string is of even length and contains two numbers of equal length
    const halfLength = hexString.length / 2;
    const firstHalf = hexString.substring(0, halfLength);
    const secondHalf = hexString.substring(halfLength);

    // Convert each half from hex to a number
    const firstNumber = parseInt(firstHalf, 16);
    const secondNumber = parseInt(secondHalf, 16);

    return [firstNumber, secondNumber];
}


export function isWeb3Wallet(web3) {
    return web3.currentProvider.isMetaMask || web3.currentProvider.isTrust || web3.currentProvider.isGoWallet || web3.currentProvider.isAlphaWallet || web3.currentProvider.isStatus || web3.currentProvider.isToshi || web3.currentProvider.isCipher || web3.currentProvider.isImToken || web3.currentProvider.isCoinbaseWallet || web3.currentProvider.constructor.name === 'EthereumProvider';
}

export function truncate(str) {
    return str.substring(0, 6);
}

export function generateDate(time_){
    // roundup to :30
    return new Date((Number(time_) +1700) * 1000).toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' });
};