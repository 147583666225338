import React, { Component } from 'react'
import { Table, Container, Spinner} from 'react-bootstrap'


class LeagueTable extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      loaded: false,
      uris: [],
      ownedNFTs: [],
      cardsData: [],
      formation: [],
      teamsData: [[]],
      cardSelected: 0
    }

  }

  async componentDidMount() {
    if (this.props.leagueId > 0){
      await this.init()
    }
  }

  async init(){  
    // console.log(this.props)
    const dataMissing = this.props.teamsData.some(teamData =>
      [teamData[3], teamData[4]].includes(undefined) ||
      [teamData[2], teamData[3], teamData[4]].includes('') ||
      isNaN(teamData[3]) ||
      isNaN(teamData[4])
    );
    if (dataMissing) {
      setTimeout(async () => {
        // console.log(" *---- GETTING DATA -----*")
        await this.props.getLeagueData();
        this.init();
      }, 1501);
    } 
  }
    
  tableRow(data) {
    if (data !== undefined){
        return (
        <>
            {data.map((item,i) => (
            <tr key={i} >
                <td>{item[0]}</td>
                <td>{item[1]}</td>
                <td>{item[2] !== '' ? item[2] : <Spinner animation="border" variant="dark" style={{borderWidth: '4px', height: '1rem', width: '1rem'}} />}</td>
                <td>{!isNaN(item[3]) ? item[3] : <Spinner animation="border" variant="dark" style={{borderWidth: '4px', height: '1rem', width: '1rem'}} />}</td>
                <td>{!isNaN(item[4])? item[4] : <Spinner animation="border" variant="dark" style={{borderWidth: '4px', height: '1rem', width: '1rem'}} />}</td>
                {/* <td>{item[5]}</td> */}
            </tr>
            ))}
        </>
        );
    } else {
      return null;
    }
  }


  render() {
    return(
      <>
      <Container>
        <Table striped bordered hover >
          <thead>
              <tr>
                <th># Rank</th>
                <th>Team Id</th>
                <th>Team Name</th>
                <th>Points</th>
                <th>Goals</th>
                {/* <th>Username</th> */}
              </tr>
          </thead>
          <tbody>
              {this.tableRow(this.props.teamsData)}
          </tbody>
          </Table>
      </Container>
      </>
    );
}
}

export default LeagueTable;