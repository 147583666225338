import React, { Component } from 'react'

class Cards extends Component {
  constructor(props) {
    super(props)

    }

    async componentDidMount() {
        
	
            document.querySelector('#card-1').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-1').classList.contains('open') ) {
                //     document.querySelector('#card-1').classList.remove('open');
                // } else {
                //     document.querySelector('#card-1').classList.remove('open');
                //     document.querySelector('#card-1').classList.add('open');
                // }
                
            });

            document.querySelector('#card-2').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-2').classList.contains('open') ) {
                //     document.querySelector('#card-2').classList.remove('open');
                // } else {
                //     document.querySelector('#card-2').classList.remove('open');
                //     document.querySelector('#card-2').classList.add('open');
                // }
                
            });

            document.querySelector('#card-3').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-3').classList.contains('open') ) {
                //     document.querySelector('#card-3').classList.remove('open');
                // } else {
                //     document.querySelector('#card-3').classList.remove('open');
                //     document.querySelector('#card-3').classList.add('open');
                // }
                
            });

            document.querySelector('#card-4').addEventListener("click", (event) => {
                
                // if ( document.querySelector('#card-4').classList.contains('open') ) {
                //     document.querySelector('#card-4').classList.remove('open');
                // } else {
                //     document.querySelector('#card-4').classList.remove('open');
                //     document.querySelector('#card-4').classList.add('open');
                // }
                
            });
        
    }
        
    render() {
        // console.log(this.state.optionsList)
        return (
        <>
        <div class="container-cards">
            <div id="card-1" class="element-card">
                <div class="front-facing">
                    <h1 className="abr">Own</h1>
                    <p class="title"> You own Team and Players, fully on-chain</p>
                </div>
                <div class="back-facing">
                    <p>Copper is a chemical element with symbol Cu (from Latin: cuprum) and atomic number 29. It is a soft, malleable, and ductile metal with very high thermal and electrical conductivity.</p>
                    <p><a class="btn" href="https://en.wikipedia.org/wiki/Copper" target="_blank">More info</a></p>
                </div>
            </div>
        
            
            <div id="card-3" class="element-card">
                <div class="front-facing">
                    <h1 class="abr">Trade</h1>
                    <p class="title">Buy and Sell single players or whole teams in one click</p>
                    <span class="atomic-number"></span>
                    <span class="atomic-mass"></span>
                </div>
                <div class="back-facing">
                    <p>Gold is a chemical element with symbol Au and atomic number 79. In its purest form, it is a bright, slightly reddish yellow, dense, soft, malleable, and ductile metal. Chemically, gold is a transition metal and a group 11 element.</p>
                    <p><a class="btn" href="https://en.wikipedia.org/wiki/Gold" target="_blank">More info</a></p>
                </div>
            </div>
            
            <div id="card-2" class="element-card">
                <div class="front-facing">
                    <h1 class="abr">Win</h1>
                    <p class="title">  Fun and simple gameplay</p>
                    <span class="atomic-number"></span>
                    <span class="atomic-mass"></span>
                </div>
                <div class="back-facing">
                    <p>Silver is the metallic element with the atomic number 47. Its symbol is Ag, from the Latin argentum, derived from the Greek ὰργὀς, and ultimately from a Proto-Indo-European language root reconstructed as *h2erǵ-, "grey" or "shining".</p>
                    <p><a class="btn" href="https://en.wikipedia.org/wiki/Silver" target="_blank">More info</a></p>
                </div>
            </div>
            
            <div id="card-4" class="element-card">
                <div class="front-facing">
                    <h1 class="abr">Earn</h1>
                    <p class="title">Win real prizes by competing against other players</p>
                    <span class="atomic-number"></span>
                    <span class="atomic-mass"></span>
                </div>
                <div class="back-facing">
                    <p>Roentgenium is a chemical element with symbol Rg and atomic number 111. It is an extremely radioactive synthetic element (an element that can be created in a laboratory but is not found in nature).</p>
                    <p><a class="btn" href="https://en.wikipedia.org/wiki/Roentgenium" target="_blank">More info</a></p>
                </div>
            </div>
        </div>
        </>
        );
    }
}

    export default Cards;