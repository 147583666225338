import React, { useState,useEffect,useRef } from 'react';
import { Card,Col, Row } from 'react-bootstrap';
import VanillaTilt from 'vanilla-tilt';
import ReactCardFlip from 'react-card-flip';

function PlayerCardMarket({ baseUrl,cardData,cardSelection}) {
  const [flipped, setFlipped] = useState(false);

  const cardWidth = 180;
  const cardHeight = 325;

  const tiltRef = useRef();
  // console.log(cardData.title, cardData.body, cardData.imgSrc,cardSelection)
  useEffect(() => {
    // Initialize VanillaTilt on the DOM element using the ref
    VanillaTilt.init(tiltRef.current, {
      max: 25, // Maximum tilt angle
      speed: 400, // Tilt speed
      glare: true, // Enable glare effect
      startY: 10,
      'max-glare': 0.5 // Max glare opacity
    });

  }, []);

  function initVanillaTilt(){
    //set timeout to wait for the flip animation to finish
    setTimeout(() => {
      VanillaTilt.init(tiltRef.current, {
        max: 25, // Maximum tilt angle
        speed: 400, // Tilt speed
        glare: true, // Enable glare effect
        startY: 10,
        'max-glare': 0.5 // Max glare opacity
      });
    }, 500);
  }

  function handleSellClick(tokenId) {
    let url = `${baseUrl}/${tokenId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
    {/* FRONT */}
    <Card ref={tiltRef} className="card-front" style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth, height: cardHeight}}>
      <Card.Img variant="top" src={cardData.imgSrc} />
      <Card.Body>
        <Card.Title>{cardData.title}</Card.Title>
        <Row className="justify-content-center" style={{paddingBottom:"8%"}}>
          <Col md="auto" style={{width: "80%"}}>
            <button className="button-formation" onClick={() => {setFlipped(!flipped); tiltRef.current.vanillaTilt.destroy(); cardData.cardAttrFunction(cardData.tokenId, true)}} style={{ width:"100%",borderRadius:"15%", textAlign:"center",}} >
                Attributes
            </button>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{paddingBottom:"8%"}}>
          <Col md="auto" style={{width: "80%"}}>
            <button className="button-formation" onClick={() => handleSellClick(cardData.tokenId)} style={{ width:"100%",borderRadius:"15%", textAlign:"center", background: "#F5ABB0"}} >
                Sell
            </button>
          </Col>
        </Row>
        {cardSelection != undefined ? (
        <Row className="justify-content-center" >
          <Col md="auto" style={{width: "80%"}}>
          {cardSelection > 0 ? (
            <>
              {cardSelection == cardData.tokenId ? (
              <button className="button-formation-selected" onClick={() => cardData.cardFunction(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"85%",borderRadius:"15%", textAlign:"center", width: "100%"}} >Unselect</button>
              ):(
              <button className="button-formation" onClick={() => cardData.cardFunction(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"60%",borderRadius:"15%", textAlign:"center",width: "100%"}} >Swap</button>
              )} 
            </>
            ) : (
            <>
              <button className="button-formation" onClick={() => cardData.cardFunction(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"65%",borderRadius:"15%", textAlign:"center",width: "100%" }} >Select</button>
            </>
            )}
          </Col>
        </Row>
        ):null}
      </Card.Body>
    </Card>
    {/* BACK */}
    <Card style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth, height: cardHeight}} onClick={() => {initVanillaTilt();setFlipped(!flipped)}}>
      <Card.Body>
        <Card.Title>{cardData.title}</Card.Title>
        <table style={{fontSize: '0.9rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
        <thead>
        <tr>
          <th>
            {cardData.body}
          </th>
        </tr>
        </thead>
        <tbody>
          {cardData.skillsName.map((row, index) => (
            <tr key={index}>
              <td>{row}</td>
              <td>{cardData.skillsValue[index]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {cardSelection != undefined ? (
          <div style={{paddingTop: "5%"}}>
          {cardSelection > 0 ? (
            <>
              {cardSelection == cardData.tokenId ? (
              <button className="button-formation-selected" onClick={() => cardData.cardFunction(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"85%",borderRadius:"15%", textAlign:"center"}} >Unselect</button>
              ):(
              <button className="button-formation" onClick={() => cardData.cardFunction(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"60%",borderRadius:"15%", textAlign:"center" }} >Swap</button>
              )} 
            </>
            ) : (
            <>
              <button className="button-formation" onClick={() => cardData.cardFunction(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"65%",borderRadius:"15%", textAlign:"center" }} >Select</button>
            </>
            )}
          </div>
      ):null}
      </Card.Body>
    </Card>
    </ReactCardFlip>
  );
}

export default PlayerCardMarket;