import React, { useState,useEffect,useRef } from 'react';
import { Card,Col, Row } from 'react-bootstrap';
import VanillaTilt from 'vanilla-tilt';
import ReactCardFlip from 'react-card-flip';
import * as formationHelper from '../../helpers/gameplay/formationHelper.js';

function PlayerCard({ cardData, cardSelection,  cardCompared, formationType, formationIndex, cardFunction, cardCompareFunction,isMobile}) {
  const [flipped, setFlipped] = useState(false);
  const [position, setPosition] = useState({scoreColor: "#FFFFFF"});

  var cardWidth = 180;
  var cardHeight = 320/2; 
  var mobileRatioWd = 1.6;
  var mobileRatioVh = 1;
  if (isMobile){
    cardWidth = 60;
    cardHeight = 120;
  }
  
  const tiltRef = useRef();
  // console.log(cardData)
  
  useEffect(() => {
    let position = formationHelper.calcPositionScore(formationType, formationIndex, cardData);
    setPosition(position);
    // Initialize VanillaTilt on the DOM element using the ref
    VanillaTilt.init(tiltRef.current, {
      max: 25, // Maximum tilt angle
      speed: 400, // Tilt speed
      glare: true, // Enable glare effect
      startY: 10,
      'max-glare': 0.5 // Max glare opacity
    });
  }, [cardData]);

  function initVanillaTilt(){
    //set timeout to wait for the flip animation to finish
    setTimeout(() => {
      VanillaTilt.init(tiltRef.current, {
        max: 25, // Maximum tilt angle
        speed: 400, // Tilt speed
        glare: true, // Enable glare effect
        startY: 10,
        'max-glare': 0.5 // Max glare opacity
      });
    }, 500);
  }

  // console.log(cardData,formationType,formationIndex)
  return (
    <>
    {!isMobile? (
    <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
    {/* FRONT */}
      <Card 
        ref={tiltRef} 
        className={cardSelection !== cardData.tokenId ? "card-front" : "card-front-selected"} 
        style={{borderColor: position.scoreColor, borderWidth: "3px" , padding: '0%', cursor: 'pointer', width: cardWidth, height: cardHeight}}
        onClick={() => {handleSelectClick(cardData.tokenId)}} 
      >
        <div style={{ position: 'relative' }}>
          <Card.Img variant="top" src={cardData.imgSrc} height="100" width="200"/>
          <div style={{ position: 'absolute', top: '5%', left: '10%' }}>
            {position.description}
          </div>
        </div>
        <Card.Body>
          <Card.Title style={{textAlign: "center"}}>
            {cardData.title}
          </Card.Title>
        </Card.Body>
      </Card>
    {/* BACK */}
      <Card style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth, height: cardHeight}} onClick={() => {initVanillaTilt();setFlipped(!flipped)}}>
        <Card.Body>
          <Card.Title>{cardData.title}</Card.Title>
          <table style={{fontSize: '0.9rem', width:"100%",borderRadius:"15%", textAlign:"center"}}>
            <thead>
              <tr>
                <th>
                  {cardData.body}
                </th>
              </tr>
            </thead>
            <tbody>
              {cardData.skillsName.map((row, index) => (
                <tr key={index}>
                  <td>{row}</td>
                  <td>{cardData.skillsValue[index] === 0 ? "N/A" : cardData.skillsValue[index]}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {cardSelection !== undefined ? (
              <div style={{paddingTop: "5%"}}>
              {cardSelection > 0 ? (
                <>
                  {cardSelection === cardData.tokenId ? (
                  <button className="button-formation-selected" onClick={() => handleSelectClick(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"85%",borderRadius:"15%", textAlign:"center"}} >Unselect</button>
                  ):(
                  <button className="button-formation" onClick={() => handleSelectClick(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"60%",borderRadius:"15%", textAlign:"center" }} >Swap</button>
                  )} 
                </>
                ) : (
                  <button className="button-formation" onClick={() => handleSelectClick(cardData.tokenId)} style={{display: 'flex',margin:"auto", width:"65%",borderRadius:"15%", textAlign:"center" }} >Select</button>
                )}
              </div>
          ):null}
        </Card.Body>
      </Card>
      </ReactCardFlip>
      ):(
        <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        {/* FRONT */}
        <Card className={cardSelection !== cardData.tokenId ? "card-front" : "card-front-selected"} style={{borderColor: position.scoreColor, borderWidth: "3px" , padding: '0%', width: cardWidth*mobileRatioWd, height: cardHeight*mobileRatioVh}}
         onClick={() => {handleSelectClick(cardData.tokenId)}} 
        >
          <Card.Img variant="top" src={cardData.imgSrc} />
          <Card.Title className="text-center">
            <div className="sub-title-mobile" style={{fontSize: "0.8rem"}}>
              {cardData.title}
            </div>
          </Card.Title>
        </Card>
        {/* BACK */}
        <Card style={{boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)', padding: '0%', width: cardWidth*mobileRatioWd, height: cardHeight*mobileRatioVh}} onClick={() => {initVanillaTilt();setFlipped(!flipped)}}>
          <Card.Body>
            <div style={{fontSize: "0.5rem"}}>
              {cardData.title}
            </div>
          </Card.Body>
        </Card>
      </ReactCardFlip>
    )}
    </>
  );
  
  function handleSelectClick(tokenId){
    // console.log("clicked"); 
    cardFunction(tokenId)
  }

  function handleCompareClick(tokenId){
    // console.log("clicked"); 
    cardCompareFunction(tokenId)
  }
}

export default PlayerCard;