import React, { useState } from 'react';
import {Form,Row,Col} from 'react-bootstrap'
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import RangeSlider from 'react-bootstrap-range-slider';

function Slider({saveParams, min, max, isMobile}) {
  const [value, setValue] = useState(0);

  const handleChange = (event) => {
    // console.log(event)
    setValue(event.target.value);
    saveParams(event.target.value)
  };

  return (
    <div>
    <Form>
      <Form.Group as={Row}>
        <Col xs="2">
        </Col>
        <Col xs="7">
        <RangeSlider
            value={value}
            variant='secondary'
            onChange={handleChange}
            min={min}
            max={max}
            step={0.0001} // Adjust the step size based on your needs
        />
        </Col>
        <Col xs="3">
          <Form.Control value={value + " ETH"}/>
        </Col>
      </Form.Group>
    </Form> 
    </div>
  );
};

export default Slider;
