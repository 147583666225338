export function generateGameActions(gameResult) {
    console.log(gameResult,gameResult[3],gameResult[4]);
    const homeGoals = gameResult[3];
    const awayGoals = gameResult[4];
    const totalGoals = Number(homeGoals) + Number(awayGoals);
    const nonGoalActions = 10 - totalGoals;

    const gameActionsArray = [];
    const goalActionsArray = [];

    for (let i = 0; i < homeGoals; i++) {
        gameActionsArray.push(getGoalHome());
        goalActionsArray.push([true, "home"])
    }

    for (let i = 0; i < awayGoals; i++) {
        gameActionsArray.push(getGoalAway());
        goalActionsArray.push([true, "away"])
    }

    for (let i = 0; i < nonGoalActions; i++) {
        gameActionsArray.push(getNonGoalAction());
        goalActionsArray.push([false, "none"])
    }

    // Shuffle the array to randomize the order of actions
    for (let i = gameActionsArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [gameActionsArray[i], gameActionsArray[j]] = [gameActionsArray[j], gameActionsArray[i]];
        [goalActionsArray[i], goalActionsArray[j]] = [goalActionsArray[j], goalActionsArray[i]];
    }
  
    // console.log(gameActionsArray, goalActionsArray);
    return [gameActionsArray, goalActionsArray];
}


export function goalRightHome1(initialPositions){
    let steps = [];
    const totalSteps = 100;
    const stepInterval = totalSteps / 6; // Interval to move the ball between groups

    // Define the final positions (structure as initialPositions)
    const finalPositions = initialPositions.map((circle, index) => {
        if (circle.color === 'red') {
            if (index !== 0){
                if (circle.groupNumber === 3) {
                    return {
                        ...circle,
                        left: circle.left + 40, 
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                } else {
                    return {
                        ...circle,
                        left: circle.left + 30, // Move 30% more towards the right
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                }
            } else {
                return circle;
            }
        } else {
            if (index !== 11){
                return {
                    ...circle,
                    left: circle.left + 5, // Move 30% more towards the right
                    top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                };
            } else {
                return circle;
            }
        }
    });    
    
    for (let step = 0; step <= totalSteps; step++) {
        let newStep = initialPositions.map((initialCircle, index) => {
            const finalCircle = finalPositions[index];

            // Interpolating positions
            const left = lerp(initialCircle.left, finalCircle.left, step / totalSteps);
            const top = lerp(initialCircle.top, finalCircle.top, step / totalSteps);

            return { ...initialCircle, left, top };
        });
        // Update ball's position at specific intervals
        // Update ball's position to follow specific players at different intervals
        let ballLeft, ballTop;
        if (step <= stepInterval) {
            // Attach ball to player at index 2
            ballLeft = newStep[2].left+2;
            ballTop = newStep[2].top;
        } else if (step <= 2 * stepInterval) {
            // Attach ball to player at index 3
            ballLeft = newStep[3].left+2;
            ballTop = newStep[3].top;
        } else if (step <= 3 * stepInterval) {
            // Attach ball to player at index 6
            ballLeft = newStep[6].left+2;
            ballTop = newStep[6].top;
        } else if (step <= 4 * stepInterval) {
            // Attach ball to player at index 9
            ballLeft = newStep[9].left;
            ballTop = newStep[9].top;
        } else if (step <= 5 * stepInterval) {
            // Attach ball to player at index 10
            ballLeft = newStep[10].left+2;
            ballTop = newStep[10].top;
        } else {
            // Attach ball to player at index 11 (gk)
            ballLeft = newStep[11].left+1;
            ballTop = newStep[11].top-5;
        }
        // console.log(ballLeft, ballTop)
        steps.push({ circles: newStep, ball: { left: ballLeft, top: ballTop } });
    }
    return steps;
}

export function goalRightAway1(initialPositions){
    let steps = [];
    const totalSteps = 100;
    const stepInterval = totalSteps / 6; // Interval to move the ball between groups

    // Define the final positions (structure as initialPositions)
    const finalPositions = initialPositions.map((circle, index) => {
        if (circle.color === 'blue') {
            if (index !== 11){
                if (circle.groupNumber === 3) {
                    return {
                        ...circle,
                        left: circle.left - 40, 
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                } else {
                    return {
                        ...circle,
                        left: circle.left - 30, // Move 30% more towards the left
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                }
            } else {
                return circle;
            }
        } else {
            if (index !== 0){
                return {
                    ...circle,
                    left: circle.left - 5, // Move 30% more towards the left
                    top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                };
            } else {
                return circle;
            }
        }
    });    
    
    for (let step = 0; step <= totalSteps; step++) {
        let newStep = initialPositions.map((initialCircle, index) => {
            const finalCircle = finalPositions[index];

            // Interpolating positions
            const left = lerp(initialCircle.left, finalCircle.left, step / totalSteps);
            const top = lerp(initialCircle.top, finalCircle.top, step / totalSteps);

            return { ...initialCircle, left, top };
        });
        // Update ball's position at specific intervals
        // Update ball's position to follow specific players at different intervals
        let ballLeft, ballTop;
        if (step <= stepInterval) {
            // Attach ball to player at index 2
            ballLeft = newStep[12].left-2;
            ballTop = newStep[12].top;
        } else if (step <= 2 * stepInterval) {
            // Attach ball to player at index 3
            ballLeft = newStep[13].left-2;
            ballTop = newStep[13].top;
        } else if (step <= 3 * stepInterval) {
            // Attach ball to player at index 6
            ballLeft = newStep[16].left-2;
            ballTop = newStep[16].top;
        } else if (step <= 4 * stepInterval) {
            // Attach ball to player at index 9
            ballLeft = newStep[17].left;
            ballTop = newStep[17].top;
        } else if (step <= 5 * stepInterval) {
            // Attach ball to player at index 10
            ballLeft = newStep[20].left-2;
            ballTop = newStep[20].top;
        } else {
            // Attach ball to player at index 11 (gk)
            ballLeft = newStep[0].left+1;
            ballTop = newStep[0].top+5;
        }
        // console.log(ballLeft, ballTop)
        steps.push({ circles: newStep, ball: { left: ballLeft, top: ballTop } });
    }
    return steps;
}

export function missLeftHome1(initialPositions){
    let steps = [];
    const totalSteps = 100;
    const stepInterval = totalSteps / 6; // Interval to move the ball between groups

    // Define the final positions (structure as initialPositions)
    const finalPositions = initialPositions.map((circle, index) => {
        if (circle.color === 'red') {
            if (index !== 0){
                if (circle.groupNumber === 3) {
                    return {
                        ...circle,
                        left: circle.left + 40, 
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                } else {
                    return {
                        ...circle,
                        left: circle.left + 30, // Move 30% more towards the right
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                }
            } else {
                return circle;
            }
        } else {
            if (index !== 11){
                return {
                    ...circle,
                    left: circle.left + 5, // Move 30% more towards the right
                    top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                };
            } else {
                return circle;
            }
        }
    });    
    
    for (let step = 0; step <= totalSteps; step++) {
        let newStep = initialPositions.map((initialCircle, index) => {
            const finalCircle = finalPositions[index];

            // Interpolating positions
            const left = lerp(initialCircle.left, finalCircle.left, step / totalSteps);
            const top = lerp(initialCircle.top, finalCircle.top, step / totalSteps);

            return { ...initialCircle, left, top };
        });
        // Update ball's position at specific intervals
        // Update ball's position to follow specific players at different intervals
        let ballLeft, ballTop;
        if (step <= stepInterval) {
            // Attach ball to player at index 2
            ballLeft = newStep[2].left+2;
            ballTop = newStep[2].top;
        } else if (step <= 2 * stepInterval) {
            // Attach ball to player at index 3
            ballLeft = newStep[3].left+2;
            ballTop = newStep[3].top;
        } else if (step <= 3 * stepInterval) {
            // Attach ball to player at index 6
            ballLeft = newStep[6].left+2;
            ballTop = newStep[6].top;
        } else if (step <= 4 * stepInterval) {
            // Attach ball to player at index 9
            ballLeft = newStep[9].left;
            ballTop = newStep[9].top;
        } else if (step <= 5 * stepInterval) {
            // Attach ball to player at index 8
            ballLeft = newStep[8].left+2;
            ballTop = newStep[8].top;
        } else {
            // Attach ball to player at index 11
            ballLeft = newStep[11].left+4;
            ballTop = newStep[11].top-12;
        }
        // console.log(ballLeft, ballTop)
        steps.push({ circles: newStep, ball: { left: ballLeft, top: ballTop } });
    }
    return steps;
}

export function missLeftAway1(initialPositions){
    let steps = [];
    const totalSteps = 100;
    const stepInterval = totalSteps / 6; // Interval to move the ball between groups

    // Define the final positions (structure as initialPositions)
    const finalPositions = initialPositions.map((circle, index) => {
        if (circle.color === 'blue') {
            if (index !== 11){
                if (circle.groupNumber === 3) {
                    return {
                        ...circle,
                        left: circle.left - 40, 
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                } else {
                    return {
                        ...circle,
                        left: circle.left - 30, // Move 30% more towards the left
                        top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                    };
                }
            } else {
                return circle;
            }
        } else {
            if (index !== 0){
                return {
                    ...circle,
                    left: circle.left - 5, // Move 30% more towards the left
                    top: circle.top + Math.random() * 10 - 5 // Randomize the top position within a range
                };
            } else {
                return circle;
            }
        }
    });    
    
    for (let step = 0; step <= totalSteps; step++) {
        let newStep = initialPositions.map((initialCircle, index) => {
            const finalCircle = finalPositions[index];

            // Interpolating positions
            const left = lerp(initialCircle.left, finalCircle.left, step / totalSteps);
            const top = lerp(initialCircle.top, finalCircle.top, step / totalSteps);

            return { ...initialCircle, left, top };
        });
        // Update ball's position at specific intervals
        // Update ball's position to follow specific players at different intervals
        let ballLeft, ballTop;
        if (step <= stepInterval) {
            // Attach ball to player at index 2
            ballLeft = newStep[12].left-2;
            ballTop = newStep[12].top;
        } else if (step <= 2 * stepInterval) {
            // Attach ball to player at index 3
            ballLeft = newStep[13].left-2;
            ballTop = newStep[13].top;
        } else if (step <= 3 * stepInterval) {
            // Attach ball to player at index 6
            ballLeft = newStep[16].left-2;
            ballTop = newStep[16].top;
        } else if (step <= 4 * stepInterval) {
            // Attach ball to player at index 9
            ballLeft = newStep[17].left;
            ballTop = newStep[17].top;
        } else if (step <= 5 * stepInterval) {
            // Attach ball to player at index 10
            ballLeft = newStep[20].left-2;
            ballTop = newStep[20].top;
        } else {
            // Attach ball to player at index 11
            ballLeft = newStep[0].left-4;
            ballTop = newStep[0].top+16;
        }
        // console.log(ballLeft, ballTop)
        steps.push({ circles: newStep, ball: { left: ballLeft, top: ballTop } });
    }
    return steps;
}

// Function for linear interpolation
const lerp = (start, end, t) => start + (end - start) * t;



/* --- SELECIONS --- */

export function getGoalHome() {
    const goalActions = [goalRightHome1];
    const randomIndex = Math.floor(Math.random() * goalActions.length);
    return goalActions[randomIndex];
}

export function getGoalAway() {
    const goalActions = [goalRightAway1];
    const randomIndex = Math.floor(Math.random() * goalActions.length);
    return goalActions[randomIndex];
}

export function getNonGoalAction() {
    const nonGoalActions = [missLeftHome1,missLeftAway1];
    const randomIndex = Math.floor(Math.random() * nonGoalActions.length);
    return nonGoalActions[randomIndex];
}