import React, {Component } from 'react';
import {Col,Row} from 'react-bootstrap'
import { Link } from 'react-router-dom';

import TeamListLoadedCards from '../components/nft/TeamListLoadedCards';
import Cards from '../components/forms/Cards.js'
import Divider from '../components/divider/Divider.js'
import bgPixels from '../static/pixel-head-bg.png'

class HomeScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
    document.body.style = 'background: #352D39;';
  }

    async componentDidMount() {
      await this.props.homepagecallback();
    }
  
    render() {
    // console.log(window.innerWidth)
    return (
          <>
          {!this.props.isMobile ? (
            <>
            {window.innerWidth < 2168 && (
            <>
              <img src={bgPixels} alt="Chainballers" className="logo" 
                style={{ position: 'absolute', width: "100vw",zIndex: -1, height:"55vw",top:0,left: 0,
                boxShadow: "0px 0px 120px 20px #57ef7c"
              }} 
              />
              <div style={{ position: 'absolute', width: "100vw",zIndex: -1, top:"49vw",left: 0}}>
                <Divider
                  colorPrimary= "#277218"
                  colorSecondary = "#49be31"
                />
              </div>
            </>
          )}
            <Row className="justify-content-md-center" style={{paddingTop:"3%"}}> 
              <Col md="auto">
                <div className='cb-title'>CHAINBALLERS</div>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{paddingTop:"0%"}}>
                <Col md="auto" xs="auto">
                  <div className='sub-title'>
                    A FULLY ONCHAIN FOOTBALL MANAGER
                  </div>
                </Col>
            </Row>
            <Row className="justify-content-md-center" style={{paddingTop:"2%"}}>
              <Col md="auto">
                <div className="subtitle-card" style={{paddingTop:"2%", paddingLeft:"4%",paddingRight:"4%"}}>
                  Chainballers is an online Football Manager Game. 
                  Players own and manage their team to try and reach the top of the league!
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" style={{paddingTop:"2%"}}>
              <Col md="auto" style={{paddingBottom: "1%"}}>
                  <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
                    <button className='gradient-button-play' onClick={() => this.handlePlayClick()}>
                      <div className='sub-title-neon'>PLAY</div>
                    </button>
                  </Link>
              </Col>
            </Row>
            <Row className="justify-content-md-center"  style={{paddingTop:"2%"}}>
              <Col md="auto">
                <div className='sub-title'>Total Prizes</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" style={{paddingTop:"0%"}}>
              <Col  md="auto">
                <div className='sub-title'>$ 1,000</div>
              </Col>
            </Row>
            <Row className="justify-content-md-center" style={{paddingTop:"2%"}}>
              <Cards/>
            </Row>
            <TeamListLoadedCards
              teamData = {this.state.sampleTeam}
              isMobile = {this.props.isMobile}
            />
            <Row className="justify-content-md-center" style={{paddingTop:"2%", paddingBottom:"2%"}}>
              <Col md="auto">
                <Row>
                  <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
                    <button className='gradient-button-play' onClick={() => this.handlePlayClick()}>
                      <div className='sub-title-neon'>OPEN APP</div>
                    </button>
                  </Link>
                </Row>
              </Col>
            </Row>
            </>
            ):(
              <>
               <img src={bgPixels} alt="Chainballers" className="logo" 
                style={{ position: 'absolute', width: "100vw",zIndex: -1, height:"55vw",top:0,left: 0,
                boxShadow: "0px 0px 120px 20px #57ef7c"
              }} 
              />
              <div style={{ position: 'absolute', width: "100vw",zIndex: -1, top:"49vw",left: 0}}>
                <Divider
                  colorPrimary= "#277218"
                  colorSecondary = "#49be31"
                />
              </div>
              <Row className="justify-content-center" style={{paddingTop:"4%"}}> 
                <Col md="auto" xs="auto">
                  <div className='cb-title-mobile'>CHAINBALLERS</div>
                </Col>
              </Row>
              <Row className="justify-content-center" style={{paddingTop:"5%"}}>
                <Col md="auto" xs="auto">
                  <div className='sub-title-mobile'>OWN</div>
                </Col>
                <Col md="auto" xs="auto" style={{paddingBottom: "5%"}}>
                  <button className='gradient-button-play' onClick={() => this.handlePlayClick()}>
                    <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
                      <div className='sub-title-mobile'>PLAY</div>
                    </Link>
                  </button>
                </Col>
                <Col md="auto" xs="auto">
                  <div className='sub-title-mobile'>WIN</div>
                </Col>
              </Row>
              <Row className="justify-content-center"  style={{paddingTop:"6%"}}>
                <Col md="auto" xs="auto">
                  <div className='sub-title-mobile'>Total Prizes</div>
                </Col>
              </Row>
              <Row className="justify-content-center" style={{paddingTop:"0%"}}>
                <Col  md="auto" xs="auto">
                  <div className='sub-title-mobile'>$ 1,000</div>
                </Col>
              </Row>
              <Row className="justify-content-center" style={{paddingTop:"2%"}}>
                <Col md="auto" xs="auto">
                  <div className="subtitle-card-mobile" >
                  Chainballers is an online Football Manager Game. 
                  Players own and manage their team to try and reach the top of the league!
                  </div>
                </Col >
              </Row>
              <Row className="justify-content-center" style={{paddingTop:"2%"}}>
                <Cards/>
              </Row>
              <Row className="justify-content-center" style={{paddingTop:"2%", paddingBottom:"2%"}}>
                <Col md="auto" xs="auto">
                  <Row className="justify-content-center " style={{paddingBottom:"2%"}}>
                    <Link to='/app' style={{textDecoration: 'none',color: 'inherit'}}>
                      <button className='gradient-button-play' onClick={() => this.handlePlayClick()} >
                        <div className='sub-title-neon' >OPEN APP</div>
                      </button>
                    </Link>
                  </Row>
                </Col>
              </Row>
              </>
            )}
            </>
      );
    }


    handlePlayClick(){
      document.body.style = 'background: #ffffff;';
    }
}
    
    export default HomeScreen;