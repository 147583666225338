import {
  CameraControls,
  Environment,
  Text3D,
  useCursor,
  useTexture,
  useMatcapTexture,
} from "@react-three/drei";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import cameraControls from 'camera-controls';


extend({ CameraControls });

const materialVillage = new THREE.MeshMatcapMaterial()


function Village(){
  const [active, setActive] = useState(null);
  const controlsRef = useRef();
  const scene = useThree((state) => state.scene);
  const map = useTexture("textures/digital_painting_the_olympics_village_a_sports_st.jpeg")

  const [ matcapTextureVillage ] = useMatcapTexture('9F9F9F_E4E4E4_D4D4D4_CCCCCC', 256)

  useEffect(() =>
  {
      matcapTextureVillage.colorSpace = THREE.SRGBColorSpace
      matcapTextureVillage.needsUpdate = true
      materialVillage.matcap = matcapTextureVillage
      materialVillage.needsUpdate = true

  }, [])


  useEffect(() => {
      if (active) {
      const targetPosition = new THREE.Vector3();
      scene.getObjectByName(active).getWorldPosition(targetPosition);
      controlsRef.current.setLookAt(
          0,
          0,
          5,
          targetPosition.x,
          targetPosition.y-1,
          targetPosition.z,
          true
      );
      } else {
      controlsRef.current.setLookAt(0, -3, 10, 0, 0, 0, true);
      }
  }, [active]);

  useFrame(() => {
      if (controlsRef.current && !active) {
          // const targetPosition = new THREE.Vector3();
          // scene.getObjectByName("village").getWorldPosition(targetPosition);
          controlsRef.current.rotateAzimuthTo(controlsRef.current.azimuthAngle -= 0.0002);
      }
  });

  return (
      <>
          <ambientLight intensity={1} />
          <Environment preset="sunset" />
          <CameraControls
              ref={controlsRef}
              maxPolarAngle={Math.PI / 1}
              minPolarAngle={Math.PI / 3}
              infinityDolly={false}
              dollyToCursor={false}
              mouseButtons={{
                  left: cameraControls.ACTION.ROTATE,
                  middle: cameraControls.ACTION.NONE,
                  right: cameraControls.ACTION.NONE,
                  shiftLeft: cameraControls.ACTION.NONE,
                  wheel: cameraControls.ACTION.NONE,
              }}
          />
          <mesh name="village">
              <sphereGeometry args={[5, 64, 64]} />
              <meshStandardMaterial map={map} side={THREE.BackSide} />
          </mesh>
          {/* <Center> */}
          <Text3D
              material = {materialVillage}
              position={[-1, -0.4, -3.051]}
              font="fonts/LuckiestGuy_Regular.json"
              size={ 0.45 }
              height={ 0.1 }
              curveSegments={ 12 }
              bevelEnabled
              bevelThickness={ 0.02 }
              bevelSize={ 0.02 }
              bevelOffset={ 0 }
              bevelSegments={ 5 }
              anchorY={"bottom"}
          >
              {"The Village"}
          </Text3D>
      </>
  )
}

export default Village;