import React, { useState, Component } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import * as utils from "../../helpers/utils";

function CreateTeamBox(props){
    const [createTeamLoading, setCreateTeamLoading] = useState(false);
    const [couponLoading, setCouponLoading] = React.useState(false);

    // console.log(props.totalCostUSD)
    // console.log(couponLoading)
    return(
        <Container className='dashboard-top-container'>
            <Row className="justify-content-center">
                <Col className="d-flex justify-content-center align-items-center">
                    <div className="col-centered comic-neue" style={{fontSize: "1.1rem",marginTop: '2%'}}>
                    You don't own a team yet! Create one below.
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                        <Col xs="auto" md="auto" className="d-flex justify-content-center align-items-center">
                            <button className="button-1" onClick={() => handleCreateTeam()}>
                            {createTeamLoading ? (
                                <Spinner animation="border" variant="dark" />
                                ) : (
                                <div>
                                    Create Team
                                </div>
                                )}
                            </button>
                        </Col>
                {/* <Col xs="auto" md="auto" className="d-flex justify-content-center align-items-center">
                    <div style={{fontStyle: "italic"}}>
                        or
                    </div>
                </Col>
                <Col xs="auto" md="auto" className="d-flex justify-content-center align-items-center">
                    <button className="button-1" onClick={() => handleCreateTeamCouponCode()}>
                        {couponLoading ? (
                            <Spinner animation="border" variant="dark" />
                            ) : (
                            <div>
                                Get Free Team Coupon  
                            </div>
                            )}
                    </button> 
                </Col> */}
            </Row>
            <Row className="justify-content-center" style={{fontStyle: 'italic'}}>
                <>
                {props.loadingGasCost ? (
                    <div style={{fontSize: "0.8rem"}}>
                        Estimating transaction cost.. <Spinner animation="border" variant="dark" style={{ height: '0.8rem', width: '0.8rem'}} />
                    </div>
                    ):(
                <>
                {props.totalCostUSD !== undefined ? (
                    <div style={{fontSize: "0.8rem"}}>
                        Team Creation Est. Gas Cost: <span style={{fontStyle: 'bold'}}> {utils.truncate(props.totalCostUSD.toString())} $ </span>
                    </div>
                ):null}
                </>
                )}
                </>
            </Row>
        </Container>
    );

    async function handleCreateTeam(){
        setCreateTeamLoading(true);
        await props.createTeam();
        setCreateTeamLoading(false);
    }

    async function handleCreateTeamCouponCode(){
        setCouponLoading(true);
        try{
            await props.createTeamCouponCode();
        } catch (err) {
            setCouponLoading(false)
        }
        setCouponLoading(false);
    }
}

export default CreateTeamBox;