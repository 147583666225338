import React, { Component } from 'react'
import { Col, Row, Container} from 'react-bootstrap'

import PlayerSlimCard from './PlayerSlimCard';
import PlayerSlim from './PlayerSlim';
import * as players from '../../helpers/players.js';
// import sampleTeam from '../../static/sampleTeam';
import sampleTeam from '../../static/sampleTeam-white';

class TeamListLoadedCards extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef();
    this.state = {
      uris: [],
      ownedNFTs: [],
      cardsData: [],
      formation: [],
      formIndexes: [[1,4],[4,9],[9,11]],
      cardSelected: 0,
      sampleTeamToShow: []
    }
  }

  async componentDidMount() {
    this.init()
  }

  async init(){
      // console.log(blob);
    let sampleTeamToShow = sampleTeam;
    // sampleTeamToShow = players.replaceBackgroundColorWithTransparency(sampleTeam, "#ffffff")
    sampleTeamToShow.forEach((item,cardIndex) => {
      let blob = new Blob([item.svgText], {type: 'image/svg+xml'});
      let url = URL.createObjectURL(blob);
      item.imgSrc = url;
    })

    this.setState({
      sampleTeamToShow: sampleTeamToShow
    })
  }



  render() {
    return(
      <>
        {this.state.sampleTeamToShow.length > 0 ? (
        <Container style={{width: '90%'}}>
          <Row className="justify-content-center" style={{ paddingBottom: '1%' }}>
            {this.state.sampleTeamToShow
            .map((item,cardIndex) => (
              <Col key={cardIndex} md={2} xs={4} style={{ paddingLeft: '1%' }}>
                <PlayerSlimCard key={item.value}
                    cardData = {this.state.sampleTeamToShow[cardIndex]} 
                />
              </Col>
            ))} 
          </Row>
        </Container>
        ):null}
      </>
    );
}
}

export default TeamListLoadedCards;