import React from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../forms/PaymentForm'; // 

function PaymentModal({ show, onHide, clientSecret, stripePromise, onSuccess,isMobile }) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="rounded-modal"
      style={isMobile ? { maxWidth: '390px' } : null}
    >
      <Modal.Header closeButton>
        <Modal.Title>Complete Your Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: '4%', paddingBottom: '6%' }}>
        <Row className="justify-content-center">
          <Col md={10} sm={12} style={{ paddingRight: '6%' }}>
            {clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentForm 
                    onSuccess={onSuccess}
                />
              </Elements>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PaymentModal;
