import React, { useState, useEffect } from "react";
import * as FaIcons from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.scss';
import { IconContext } from 'react-icons';


function Navbar() {

  useEffect(() => {
    // console.log("checking size")
    setIsMobile(window.innerWidth < 1000)
    if (window.innerWidth < 1000) {
      setNavMenuActive('nav-menu-mobile active'); 
    } else {
      setNavMenuActive("nav-menu active");
      }
  });


  const [isMobile, setIsMobile] = useState(false)
  const [navMenuActive, setNavMenuActive] = useState("nav-menu active")
  const [sidebar, setSidebar] = useState(window.innerWidth > 1000);
  const [selectedItem, setSelectedItem] = useState("Dashboard")

  const showSidebar = () => {
    // console.log("sidebar")
    setSidebar(!sidebar);
  }

  const showSidebarConditional = () => {
    if (isMobile) {
      setSidebar(!sidebar);
    } else {}
  }

  // console.log(selectedItem)
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        {isMobile ? (
          <>
            <div className='navbar'>
                <Link to='#' className='menu-bars'>
                {/* <img src={logogif5} height='40' alt=""/>  */}
                  <FaIcons.FaBars onClick={showSidebar} className="bordered" />
                </Link>
            </div>
          </>
        ):null}
        <nav className={sidebar ? navMenuActive : 'nav-menu'}>
          <ul className='nav-menu-items' onClick={showSidebarConditional}>
            <li className='navbar-toggle'style={{margin: "0 0 3rem 0"}}>
              {/* <span style={{fontSize: '30px', color:'white'}}>
                <Link to='/'>
                  <AiIcons.AiFillHome className="bordered"/>
                </Link>
              </span> */}
              <div style={{color: 'white',fontStyle: 'italic'}}>
                <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <div className='sub-title' style={{paddingLeft:"18%",paddingTop:"5%",fontSize: '20pt'}}>CHAINBALLERS</div>
                </Link>
              </div>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} 
                  className={item.title === selectedItem ? 'nav-text-selected': item.cName }
                  // className={item.cName}
                  onClick={() => setSelectedItem(item.title)}>
                  {item.path === '/docs' ? (
                    <a href="https://docs.chainballers.xyz/" target="_blank">
                    {item.icon}
                    <span className="navbar-title">{item.title}&nbsp;&nbsp;</span>
                    {item.icon2}
                    </a>
                  ):(
                    <Link to={item.path}>
                    {item.icon}
                    <span className="navbar-title">{item.title}&nbsp;&nbsp;</span>
                    {item.icon2}
                  </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
    
  );
}

export default Navbar;
