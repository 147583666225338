
  export async function switchToChain(web3, chainId) {
    try {
      // Request MetaMask to enable the Ethereum provider
      await window.ethereum.enable();
      // Check if MetaMask is connected
      if (web3.currentProvider.isMetaMask) {
        try{
          console.log(`Switching to ${chainId} network`);
        // Switch to the network
          await web3.currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [
              { chainId: chainId },
            ],
          });
          console.log(`Switched to ${chainId} network`);
        } catch (error) {
          if (chainId = "0x2105"){
            await this.addBaseChain();
          }
        }
      } else {
        console.error('MetaMask not detected');
      }
    } catch (error) {
      console.error('Error switching network:', error);
    }
  }


  export async function addBaseChain() {
    try {
      // Check if MetaMask is installed
      if (window.ethereum) {
        // Request to add the network
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId: '0x2105', // The chain ID of the network, in hexadecimal
            chainName: 'Base', // A human-readable name for the network
            nativeCurrency: {
              symbol: 'ETH', 
              decimals: 18,
            },
            rpcUrls: ['https://mainnet.base.org/'], // The URL of the RPC endpoint to use for this network
            blockExplorerUrls: ['https://basescan.org'], // The URL of the block explorer for this network
          }],
        });
      } else {
        console.log('MetaMask is not installed!');
      }
    } catch (error) {
      console.error('Failed to add the network', error);
    }
  }
