import React, {Component} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.scss';

import INFTContract from './contracts/INFTContract.json'

import Navbar from './components/navbar/Navbar';
import LoginBanner from './components/LoginBanner';
import PaymentModal from './components/modal/PaymentModal';

import HomeScreen from './pages/HomeScreen'
import Dashboard from './pages/Dashboard';
import Schedule from './pages/Schedule';
import Formation from './pages/Formation';
import Training from './pages/Training';
import Market from './pages/Market';
import Explore from './pages/Explore';
import Referral from './pages/Referral';
import Background from './pages/Background';

import PlayerModal from './components/modal/PlayerModal';

// helpers
import * as players from './helpers/players.js';
import * as utils from './helpers/utils.js';
import * as api from './helpers/api.js';
import * as coreWeb3 from './helpers/coreWeb3';
import * as pay from './helpers/stripe.js';
import gameResults from './data/pastGames.js';

// payment
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
const stripePromise = loadStripe('pk_test_51PtGtB065mtL9wAPqSYHTDq9FGqIGrAPwFaieCxLMVOi4dPWYMPJ70YKuIRNrjtdql6KVg9V2N1xojl4WUj7qUrO00kWdVkRIG');



class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isHome: false,
      totalPrizes: 0,
      leaguePrize:0,
      nextGameInfo: [],
      teamNFTAddress: '0x44fB4aD021A89f23807b63320E201d90D91271be',
      teamNFTAddressL1: '0x4f48311D92B4Da00B1f52E7dCf06A4504854275B',
      CBAddress: '0xF4AcaC4ADE4a1f33984EcD97f064927ccAFa03a4',
      CBAddressL1: '0xde2fC11b3222E9754A23A12094Ab70116f0e493E'
    }
    this.handleResize = this.handleResize.bind(this);
    this.homepageCallback = this.homepageCallback.bind(this);
    this.scheduleCallback = this.scheduleCallback.bind(this);
    this.dashboardCallback = this.dashboardCallback.bind(this);
    this.formationCallback = this.formationCallback.bind(this);
    this.trainingCallback = this.trainingCallback.bind(this);
    this.marketCallback = this.marketCallback.bind(this);
    this.exploreCallback = this.exploreCallback.bind(this);
    this.referralCallback = this.referralCallback.bind(this);
    this.loginButtonFallback = this.loginButtonFallback.bind(this);
    this.onLeagueSelectFallback = this.onLeagueSelectFallback.bind(this);
    this.onPlayerSelectFallback = this.onPlayerSelectFallback.bind(this);
    this.timerSetter = this.timerSetter.bind(this);
    this.loadAccountData = this.loadAccountData.bind(this);
    this.loadTeamOnL1 = this.loadTeamOnL1.bind(this);
    this.loadFormationById = this.loadFormationById.bind(this);
    this.setTeamName = this.setTeamName.bind(this);
    this.setClientSecret = this.setClientSecret.bind(this);
    this.createSponsoredTeam = this.createSponsoredTeam.bind(this);
    this.getTeamName = this.getTeamName.bind(this);
    this.getPastGameResult = this.getPastGameResult.bind(this);
    this.getNumberOfLeagues = this.getNumberOfLeagues.bind(this);
    this.getOwnedTeam = this.getOwnedTeam.bind(this);
    this.getTeamNextGameId = this.getTeamNextGameId.bind(this);
    this.getFormationForGame = this.getFormationForGame.bind(this);
    this.getLeagueFullData = this.getLeagueFullData.bind(this);
    this.getLeagueDataDB = this.getLeagueDataDB.bind(this);
    this.handlePlayGame = this.handlePlayGame.bind(this);
    this.getAvailableGames = this.getAvailableGames.bind(this);
    this.getPlayersData = this.getPlayersData.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.onPaymentSuccess = this.onPaymentSuccess.bind(this);
  }

  async componentDidMount() {
    this.checkSize();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener('resize', this.handleResize);
  }

  checkSize() {
    this.setState({
        isMobile: window.innerWidth < 1000
    });
  }

  handleResize() {
    if (window.innerWidth < 1000){
      this.setState({
        isMobile: window.innerWidth < 1000
      });
    } else if (this.state.isMobile) {
      this.setState({
        isMobile: window.innerWidth < 1000
      });
    }
  }

    /*------ FALLBACKS ------*/

  async loginButtonFallback(loginData){
      console.log(loginData)
      let web3 = loginData.web3 !== undefined ? loginData.web3 : this.state.web3
      // loginData.signer = "0x7bF8ee10E37498b1338c64458ec3a546F38BC40D"
      this.setState({
        account: loginData.signer,
        ownedSafes: loginData.ownedSafes,
        ownedSafesData: loginData.ownedSafesData,
        web3: web3,
        web3l2: loginData.web3l2,
      })
      await this.loadAccountData()
  }

  async onLeagueSelectFallback(leagueId){
    this.setState({
      showLeaguePage: true,
      selectedLeaguePage: leagueId
    })
  }

  onPlayerSelectFallback(playerData){
    this.setPlayerModalShow(true)
    this.setPlayerModalData(playerData)
  }

  setPlayerModalShow(value){
    this.setState({
      showPlayerModal: value
    })
  }

  setPlayerModalData(value){
    this.setState({
      playerModalData: value
    })
  }

  setClientSecret(value){
    this.setState({
      clientSecret: value
    })
  }

  // Arrayfy this to 1 liner
  async homepageCallback(){
    this.setState({
      isDashboard: false,
      isSchedule: false,
      isFormation: false,
      isTraining: false,
      isExplore: false,
      isReferral: false,
      isMarket: false,
      isHome: true
    })
  }

  async scheduleCallback(){
    this.setState({
      isHome: false,
      isDashboard: false,
      isFormation: false,
      isTraining: false,
      isExplore: false,
      isReferral: false,
      isMarket: false,
      isSchedule: true
    })
  }

  async dashboardCallback(){
    this.setState({
      isHome: false,
      isSchedule: false,
      isFormation: false,
      isTraining: false,
      isExplore: false,
      isReferral: false,
      isMarket: false,
      isDashboard: true
    })
  }

  async formationCallback(){
    this.setState({
      isHome: false,
      isSchedule: false,
      isDashboard: false,
      isTraining: false,
      isExplore: false,
      isReferral: false,
      isMarket: false,
      isFormation: true
    })
  }

  async trainingCallback(){
    this.setState({
      isHome: false,
      isSchedule: false,
      isDashboard: false,
      isFormation: false,
      isExplore: false,
      isReferral: false,
      isMarket: false,
      isTraining: true
    })
  }

  async marketCallback(){ 
    this.setState({
      isHome: false,
      isSchedule: false,
      isDashboard: false,
      isFormation: false,
      isTraining: false,
      isExplore: false,
      isReferral: false,
      isMarket: true
    })
  }

  async exploreCallback(){
    this.setState({
      isHome: false,
      isSchedule: false,
      isDashboard: false,
      isFormation: false,
      isTraining: false,
      isMarket: false,
      isReferral: false,
      isExplore: true
    })
  }

  async referralCallback(){
    this.setState({
      isHome: false,
      isSchedule: false,
      isDashboard: false,
      isFormation: false,
      isTraining: false,
      isExplore: false,
      isReferral: true
    })
  }

   /*------ CB FUNCTION ------*/

  // Launched at login
  async loadAccountData(){
      const promises = [];
      await this.getTeam();
      // console.log(this.state.ownedTeamId)
      if (this.state.ownedTeamId > 0){
        try{
          promises.push(api.addTeam(this.state.account, 1));
          promises.push(api.updateTeam(this.state.account, 1));
          promises.push(this.getPlayersData());
        } catch (error) {
          // console.log(error)
        }
      }
      promises.push(this.getPrizes());
      promises.push(this.getBalance(this.state.account));
      
      if (this.state.isSchedule){
        promises.push(this.getTeamNextGameId());
        promises.push(this.getFormationForGame());
      }
    
      if (this.state.isDashboard){
        // promises.push(this.getLeagueData());
      }
    
      await Promise.all(promises);
    // console.timeEnd('getTeam')
  }

  async loadTeamOnL1(txReceipt){
    await this.getTeamOnL1();
    await this.getPlayersL1(txReceipt);
  }

  async setTeamName(name){
    // console.log(name)
    const network = await this.state.web3.eth.net.getId();
    if (network !== 8453){
      console.log("switching to chain")
      await coreWeb3.switchToChain(this.state.web3, "0x2105");
    }
    let CBContract = new this.state.web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
    await CBContract.methods.setTeamName(this.state.ownedTeamId,name).send({ 
      from: this.state.account,
      maxPriorityFeePerGas: null, 
      maxFeePerGas: null
    }).on('receipt', txHash =>
    {
        // console.log(txHash)
        this.setState({
          ownedTeamName: name
        })
    })
  }

  async getAvailableGames(leagueId){
    let availableGames = await api.getAvailableGames(leagueId, 8453);
    // console.log(availableGames)
    availableGames = availableGames.filter(game => game !== null);
    // console.log(availableGames)
    this.setState({
      availableGames: availableGames
    })
    return availableGames;
  }

  async getCanTrain(tokenId){
    let web3 = this.state.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
    let canTrain = await CBContract.methods.canTrain(tokenId).call();
    console.log(canTrain)
    this.setState({
      canTrain: {...this.state.canTrain, tokenId : canTrain}
    })
  }

  async getNetwork(){
    const network = await this.state.web3.eth.net.getId()
    let isRequiredNetwork = (network === 1)
    this.setState({
      network: network,
      isRequiredNetwork: isRequiredNetwork
    })
  }

  async getBalance(account){
    let web3 = this.state.web3
    const balance = web3.utils.fromWei(
      await web3.eth.getBalance(account) // Balance is in wei
    );
    this.setState({
      balance: balance
    })
  }

  async getTeam(){
    let web3 = this.state.web3
    let account = this.state.account;
    if (web3 !== undefined){
      if (account !== undefined){
        let teamData = await api.getTeamByOwner(account)
        // console.log(teamData)
        this.setState({
            ownedTeamId: teamData.team_id,
            ownedTeamLeague: teamData.league_id,
            ownedTeamName: teamData.team_name
        })
        // console.log(this.state.ownedTeamId)
        // console.log(this.state.ownedTeamLeague)
      }
    }
  }

  async getTeamOnL1(){
    let web3 = this.state.web3
    let account = this.state.account;
    if (web3 !==undefined){
      if (account !== undefined){
        // get leagueIds counter
        await this.getOwnedTeamL1() // sets ownedTeamId
        
        console.log(this.state.ownedTeamId)
        this.setState({
            ownedTeamLeague: Math.max(1, Math.ceil(this.state.ownedTeamId / 8))
            // numLeagues: _nLeagues
        })
      
        console.log(this.state.ownedTeamId)
        console.log(this.state.ownedTeamLeague)

      }
    }
  }

  async getPlayersL1(txReceipt){
    let events = Object.values(txReceipt.events);
    let playerIds = [];
    console.log(events)
    console.log(events.length)
    for (let i = 0; i < events.length; i++) {
      console.log(events[i].address)
      console.log(this.state.CBAddressL1)
      if (events[i].address.toLowerCase() === this.state.CBAddressL1.toLowerCase()){
        console.log(events[i].raw.topics[0].toLowerCase())
        if (events[i].raw.topics[0].toLowerCase() === "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef".toLowerCase()){
          console.log(parseInt(events[i].raw.topics[3], 16));
          playerIds.push(parseInt(events[i].raw.topics[3], 16))
        }
      }
    }
    console.log(playerIds)
    var ballerData = await api.getBallersByTeam(this.state.ownedTeamId, 1)
    console.log(ballerData)
    if (ballerData.length === 0) {
      ballerData = []
      ballerData = await Promise.all(playerIds.map(nft => api.addBaller(nft, 1)));
      console.log(ballerData)
    }
    this.setState({
      ownedPlayers: ballerData
    })
  }

  async getTeamNextGameId(){
    let web3 = this.state.web3
    let gameId;
    if (web3 !== undefined){
      let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
      await CBContract.methods.getTeamNextGameId(this.state.ownedTeamId).call().then(
        _id => {
          // console.log(_id)
          gameId = _id
        }
      )
    }

    this.setState({
        nextGameId: gameId
    })
  }

  async getFormationForGame(side){
    let web3 = this.state.web3
    if (web3 !== undefined){
      let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
      var nextGameFormationType;
      var nextGameFormation;
      await CBContract.methods.getGameInfo(this.state.nextGameId).call().then(
        _info => {
          // console.log(_info)
          // side = this.getGameSide(_info,this.state.ownedTeamId)
          // console.log(side)
          this.setState({
            nextGameInfo: _info
          })
        }
      )

      try{
        await CBContract.methods.getTeamFormationType(this.state.ownedTeamId,side).call().then(
          _formation => {
            // console.log(_formation)
            nextGameFormationType = _formation
            // this.setState({
            //   nextGameFormationType: _formation
            // })
          }
        )
      } catch (error) {
        // console.log(error)
      }

      try{
        await CBContract.methods.getTeamFormation(this.state.ownedTeamId,side).call().then(
          _formation => {
            // console.log(_formation)
            nextGameFormation = _formation
            // this.setState({
            //   nextGameFormation: _formation
            // })
          }
        )
      } catch (error) {
        // console.log(error)
      }
    }
    return [nextGameFormationType,nextGameFormation];
  }

  async loadFormationById(teamId){
    console.log("TEAM ID: ", teamId)
    let web3 = this.state.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);

    var defaultFormation = []
    var formationType = []
    try{
      await CBContract.methods.getDefaultFormation(teamId).call().then(
        _indexes => {
            // console.log(_indexes)
            let indexes = _indexes;
            defaultFormation = indexes[1]
            formationType = [indexes[0]]
        }
      )
    } catch (error) {
      // console.log(error)
    }
    defaultFormation = defaultFormation.map(id => parseInt(id, 10));
    // console.log(defaultFormation)
    if (teamId == this.state.ownedTeamId){
      defaultFormation = defaultFormation.map(id => this.state.ownedPlayers.find(baller => baller.baller_id === id)).filter(baller => baller !== undefined);
    } else {
      var ballersData = await api.getBallersByTeam(teamId, 1)
      defaultFormation = defaultFormation.map(id => ballersData.find(baller => baller.baller_id === id)).filter(baller => baller !== undefined);
    }
    // console.log(defaultFormation)
    return [formationType,defaultFormation];
  }

  getGameSide(info,teamId){
    if (info[0] === teamId){
      return 1;
    } else if (info[1] === teamId){
      return 2;
    } else {
      return 400
    }
  }
  async getOwnedTeam(){
    // console.log(this.state.account,this.state.teamNFTAddress)
    let web3 = this.state.web3
    var network = await web3.eth.net.getId();
    if (network == 1){
      return await this.getOwnedTeamL1();
    }
    let teamNFTContract = new web3.eth.Contract(INFTContract.abi, this.state.teamNFTAddress);
    var ownedTeamId = 0;
    try{
      await teamNFTContract.methods.tokenOfOwnerByIndex(this.state.account,0).call().then(
        _indexes => {
            // console.log(_indexes)
            let indexes = _indexes;
            ownedTeamId = indexes
            this.setState({
                ownedTeamId: indexes
            })
        }
    )
    } catch (error){
      this.setState({
        ownedTeamId: undefined
      })
    }
    try{
      let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
      await CBContract.methods.getTeamName(ownedTeamId).call().then(
        _name => {
            // console.log(_name)
            if (_name === '') {
              this.setState({
                  ownedTeamName: undefined
              })
            } else {
              this.setState({
                ownedTeamName: _name
              })
            }
        }
      )
    } catch (error){
      this.setState({
        ownedTeamName: undefined
      })
    }
    return ownedTeamId;
  }

  async getOwnedTeamL1(){
    console.log(this.state.account,this.state.teamNFTAddress)
    let web3 = this.state.web3
    let teamNFTContract = new web3.eth.Contract(INFTContract.abi, this.state.teamNFTAddressL1);
    var ownedTeamId = 0;
    try{
      await teamNFTContract.methods.tokenOfOwnerByIndex(this.state.account,0).call().then(
        _indexes => {
            console.log(_indexes)
            let indexes = _indexes;
            ownedTeamId = indexes
            this.setState({
                ownedTeamId: ownedTeamId
            })
        }
    )
  } catch (error){
    this.setState({
      ownedTeamId: undefined
    })
    }
    return ownedTeamId;
  }

  async getNumberOfLeagues(){
    let web3 = this.state.web3
    if (web3 !== undefined){
      const CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
      var leaguesArray = []
      await CBContract.methods.getNumberOfLeagues().call().then(
        _nLeagues => {
            leaguesArray = new Array(_nLeagues);
            this.setState({
                numLeagues: leaguesArray
            })
        }
      )
    }
    return leaguesArray;
  }

  async getPrizes(networkId){
    if (networkId ==  "0x2105"){
      // console.log("fetching prizes..")
      let web3 = this.state.web3
      if (web3 !== undefined){
        const CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
        // Get total prizes
        // get leagueIds counter
        await this.getNumberOfLeagues()

        await CBContract.methods.getTotalAccrued().call().then(
          undistributed => {
            // console.log(undistributed)
            this.setState({
              undistributed: undistributed
            })
        }
        )
        this.getEthPrice()
        try {
          await CBContract.methods.getLeagueTotalPrize(this.state.ownedTeamLeague).call().then(
            prize => {
                // console.log(prize)
                // console.log(this.state.ethPrice)
                let und = this.state.undistributed/this.state.numLeagues
                // console.log(und)
                let leaguePrize = (parseInt(prize) + parseInt(und))/Math.pow(10,18) * this.state.ethPrice
                // console.log(leaguePrize)
                // if (leaguePrize == NaN) {leaguePrize = 0 }
                this.setState({
                    leaguePrize: leaguePrize
                    // numLeagues: _nLeagues
                })
            }
          )
        } catch (error) {
          // console.log(error)
        }
    
        let prizes = 0;
        for (let i = 1; i <= this.state.numLeagues; i++) {
          try{
            await CBContract.methods.getLeagueTotalPrize(i).call().then(
              prize => {
                  let und = this.state.undistributed/this.state.numLeagues
                  prizes += (parseInt(prize) + parseInt(und))
                  this.setState({
                      totalPrizes: prizes/Math.pow(10,18) * this.state.ethPrice
                      // numLeagues: _nLeagues
                  })
              }
            )
          } catch (error) {
            console.log(error)
          }
        }
      }
    }
  }

  getEthPrice = () => {
    this.setState({
      ethPrice: 2500
    })
  }

  async getPlayersData(){
    console.log("fetching players..")
    console.log("TEAM ID: ", this.state.ownedTeamId)
    try {
      var ballerData = await api.getBallersByTeam(this.state.ownedTeamId, 1)
      if (ballerData.length === 0) {
        console.log("No players in DB.")
        /* ADD NEW PLAYERS */
        // console.log(this.state.account,this.state.CBAddress)
        let _nfts = await players.getOwnedPlayers(this.state.account,this.state.CBAddressL1, this.state.web3)
        console.log(_nfts)
        ballerData = []
        ballerData = await Promise.all(_nfts.map(nft => api.addBaller(nft, 1)));
        console.log(ballerData)
      }
    } catch (error) {
      // console.log(error)
    }

    if (ballerData.length === 0) {
      console.log("Looking for players on L1.")
      /* ADD NEW PLAYERS */
      let _nfts = await players.getOwnedPlayers(this.state.account,this.state.CBAddressL1, this.state.web3)
      console.log(_nfts)
      ballerData = []
      ballerData = await Promise.all(_nfts.map(nft => api.addBaller(nft, 1)));
      console.log(ballerData)
    }
    this.setState({
      ownedPlayers: ballerData
    })

    return ballerData;
  }

  async getLeagueDataDB(leagueId){
    let leagueData = await api.getLeagueData(leagueId, 1); // (leagueId, chainId)
    // console.log(leagueData)
    const selectedFields = leagueData.map(obj => [
      obj.rank,
      obj.team_id,
      obj.team_name,
      obj.points,
      obj.goals
    ]);
  
    // console.log(selectedFields)
    // Sort the array based on the rank (the first element of each sub-array)
    selectedFields.sort((a, b) => a[0] - b[0]);
  
    this.setState({
      teamsData: selectedFields
    })
    // console.log(selectedFields)
    return selectedFields;
  }

  getValue(id,arr) { return parseInt((arr.find(pair => pair[0] === id.toString()) || [])[1], 10)};

  timerSetter() {
    this.setState({
      startTimer: true
    })
  }
  
  async getTeamName(id){
    let web3 = this.state.web3;
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
    let name = ""
    try {
      await CBContract.methods.getTeamName(id).call().then(
        _name => {
            // console.log(_name)
            if (_name === '') {
              name = 'Team '+id.toString()
            } else {
              name = _name
            }
        }
      )
    } catch (error) {
      // console.log(error)
    }
    return name;
  }

  async getLeagueFullData(){
    let web3 = this.state.web3;
    // console.log(this.state.ownedTeamLeague)
    var leagueFullData = []
    if (web3 !== undefined){
      const CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
      await CBContract.methods.getLeagueData(this.state.ownedTeamLeague).call().then(
        leagueFullData_ => {
            // console.log(leagueFullData)
            leagueFullData = leagueFullData_
            // this.setState({
            //     leagueFullData: leagueFullData_
            // })
        }
      )
    }
    // console.log(leagueFullData)
    return leagueFullData;
  }

  async getPastResults(pastGames){
    console.log(pastGames)
    let gr = []
    for (let i = 0; i < pastGames.length; i++) {
      gr.push(gameResults[pastGames[i][3]])
    }
    // console.log(gr)
    return gr;
  }

  async handlePlayGame(gameId){
    let web3 = this.state.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
    var gameResult = []

    const receipt = await new Promise((resolve, reject) => {
        CBContract.methods.startGame(gameId).send({
            from: this.state.account,
            maxPriorityFeePerGas: null, 
            maxFeePerGas: null
        }).on('receipt', resolve).on('error', reject);
    });

    let _info = await CBContract.methods.getGameInfo(gameId).call();
    gameResult = _info;
    // console.log(gameResult)
    return gameResult;
}

  async getPastGameResult(gameTime, gameId){
    // console.log(gameTime, gameId)
    let pastGameResult = gameResults[gameId];
    if (pastGameResult === undefined){
      const currentBlockNumber = await this.state.web3.eth.getBlockNumber();
      let gameBlock = await utils.findBlockByTimestamp(6790000, currentBlockNumber, gameTime, this.state.web3)
      let topics = ["0x52da0466f8325d3f6fb48dd00c1b2a69f03effc8681ef5ba6d43a02f45fbd151", utils.formatToHex(gameId)]
      let events = await utils.searchEvents(gameBlock, this.state.CBAddress,topics, this.state.web3)
      let gameResultArray = utils.hexStringToNumbers(events[0].data)
      pastGameResult = gameResultArray.join(' - ');
    }
    return pastGameResult;
  }

  async createSponsoredTeam(sponsoredAddress){
    let web3 = this.state.web3
    let CBContract = new web3.eth.Contract(INFTContract.abi, this.state.CBAddress);
    await CBContract.methods.createSponsoredTeam(sponsoredAddress).send({
      from: this.state.account,
      maxPriorityFeePerGas: null, 
      maxFeePerGas: null
    }).on('receipt', txHash =>
    {
        console.log(txHash)
    })
  }

  async handlePayment() {
    const secret = await pay.payWithStripe(5000, "usd");
    this.setClientSecret(secret);
  };

  async onPaymentSuccess(){
    this.setClientSecret(null);
  }

  render() {
    // console.log(this.state.isHome)
    return (
      <>
      <Router>  
        {!this.state.isHome && !this.state.isExplore && !this.state.isDashboard ? (
          <>
          <Navbar/>
          <LoginBanner
            loginButtonFallback={this.loginButtonFallback}
            signer={this.state.account}
            startTimer={this.state.startTimer}
            isMobile={this.state.isMobile}
          />
          </>
        ): null}
        <Routes> 
          <Route path='/' exact element={
            <HomeScreen
              homepagecallback={this.homepageCallback}
              isMobile={this.state.isMobile}
            />
          } 
          />
          <Route path='/app' element={
            <>
              {!this.state.isMobile && this.state.account !== undefined && this.state.ownedTeamId == 0 && (
                <Background/>)
              }
              <Navbar/>
              <LoginBanner
                loginButtonFallback={this.loginButtonFallback}
                signer={this.state.account}
                startTimer={this.state.startTimer}
                isMobile={this.state.isMobile}
              />
              <Dashboard 
                handlesigninclick={this.handlesigninclick}
                dashboardCallback={this.dashboardCallback}
                account = {this.state.account}
                web3 = {this.state.web3}
                balance = {this.state.balance}
                CBAddress = {this.state.CBAddress}
                CBAddressL1 = {this.state.CBAddressL1}
                teamNFTAddressL1 = {this.state.teamNFTAddressL1}
                leaguePrize = {this.state.leaguePrize}
                totalPrizes = {this.state.totalPrizes}
                selectedTeam = {this.state.selectedTeam}
                setTeamName = {this.setTeamName}
                ownedTeamId = {this.state.ownedTeamId}
                ownedTeamLeague = {this.state.ownedTeamLeague}
                ownedTeamName = {this.state.ownedTeamName}
                ownedPlayers={this.state.ownedPlayers}
                teamsData = {this.state.teamsData}
                getLeagueData = {this.getLeagueDataDB}
                getNumberOfLeagues = {this.getNumberOfLeagues}        
                loginButtonFallback={this.loginButtonFallback}
                onLeagueSelectFallback={this.onLeagueSelectFallback}
                handlePlayerSelect={this.onPlayerSelectFallback}
                loadTeamOnL1={this.loadTeamOnL1}
                timerSetter={this.timerSetter}
                payWithStripe={this.handlePayment}
                isMobile={this.state.isMobile}
              />
              <PlayerModal
                show={this.state.showPlayerModal}
                onHide={() => this.setPlayerModalShow(false)}
                playerData={this.state.playerModalData}
                isMobile={this.state.isMobile}
              />
              <PaymentModal 
                show={!!this.state.clientSecret} 
                onHide={() => this.setState({ clientSecret: null })} 
                clientSecret={this.state.clientSecret} 
                stripePromise={stripePromise} 
                onSuccess={this.onPaymentSuccess}
                isMobile={this.state.isMobile} 
              />
            </>
          }/>
          <Route path='/schedule' element={
            <>
              <Schedule
                handlesigninclick={this.handlesigninclick}
                scheduleCallback = {this.scheduleCallback}
                loginButtonFallback={this.loginButtonFallback}
                account ={this.state.account}
                web3 = {this.state.web3}
                magic = {this.state.magic}
                CBAddress = {this.state.CBAddress}
                teamNFTAddress = {this.state.teamNFTAddress}
                ownedTeamId = {this.state.ownedTeamId}
                ownedTeamLeague = {this.state.ownedTeamLeague}
                getOwnedTeam = {this.getOwnedTeam}
                getTeamNextGameId = {this.getTeamNextGameId}
                getFormationForGame = {this.getFormationForGame}
                loadFormationById = {this.loadFormationById}
                getLeagueFullData = {this.getLeagueFullData}
                getLeagueData = {this.getLeagueDataDB}
                handlePlayGame = {this.handlePlayGame}
                availableGames = {this.state.availableGames}
                getAvailableGames = {this.getAvailableGames}
                nextGameId = {this.state.nextGameId}
                nextGameInfo = {this.state.nextGameInfo}
                nextGameFormationType = {this.state.nextGameFormationType}
                nextGameFormation = {this.state.nextGameFormation}
                getTeamName = {this.getTeamName}
                teamsData = {this.state.teamsData}
                getPastGameResult = {this.getPastGameResult}
                isMobile={this.state.isMobile} 
              />
            </>
          } />
          <Route path='/formation' element={
            <>
              <Formation
                handlesigninclick={this.handlesigninclick}
                formationCallback={this.formationCallback}
                loginButtonFallback={this.loginButtonFallback}
                getOwnedTeam = {this.getOwnedTeam}
                getPlayersData = {this.getPlayersData}
                account ={this.state.account}
                web3 = {this.state.web3}
                magic = {this.state.magic}
                CBAddress = {this.state.CBAddress}
                teamNFTAddress = {this.state.teamNFTAddress}
                nextGameId = {this.state.nextGameId}
                ownedTeamId = {this.state.ownedTeamId}
                ownedPlayers={this.state.ownedPlayers}
                startTimer={this.state.startTimer}
                isMobile={this.state.isMobile}
              />
            </>
          } />
          <Route path='/training' element={
            <>
              <Training
                handlesigninclick={this.handlesigninclick}
                trainingCallback={this.trainingCallback}
                loginButtonFallback={this.loginButtonFallback}
                account ={this.state.account}
                web3 = {this.state.web3}
                magic = {this.state.magic}
                CBAddress = {this.state.CBAddress}
                teamNFTAddress = {this.state.teamNFTAddress}
                ownedTeamId = {this.state.ownedTeamId}
                ownedPlayers={this.state.ownedPlayers}
                startTimer={this.state.startTimer}
                isMobile={this.state.isMobile} 
              />
            </>
          } />
          <Route path='/market' element={
            <>
              <Market
                marketCallback={this.marketCallback}
                loginButtonFallback={this.loginButtonFallback}
                account ={this.state.account}
                web3 = {this.state.web3}
                CBAddress = {this.state.CBAddress}
                CBAddressL1 = {this.state.CBAddressL1}
                ownedPlayers={this.state.ownedPlayers}
                isMobile={this.state.isMobile} 
              />
            </>      
          } />
          <Route path='/explore' element={
            <>
              <Explore
                exploreCallback={this.exploreCallback}
                loginButtonFallback={this.loginButtonFallback}
                account ={this.state.account}
                web3 = {this.state.web3}
                CBAddress = {this.state.CBAddress}
                CBAddressL1 = {this.state.CBAddressL1}
                ownedPlayers={this.state.ownedPlayers}
                isMobile={this.state.isMobile} 
              />
              {!this.state.isMobile ? (
                <>
                <Navbar/>
                <LoginBanner
                  loginButtonFallback={this.loginButtonFallback}
                  signer={this.state.account}
                  isMobile={this.state.isMobile}
                />
                </>    
              ): null}
            </>      
          } />
          <Route path='/referral' element={
            <>
              <Referral
                referralCallback={this.referralCallback}
                loginButtonFallback={this.loginButtonFallback}
                account ={this.state.account}
                web3 = {this.state.web3}
                CBAddress = {this.state.CBAddress}
                CBAddressL1 = {this.state.CBAddressL1}
                ownedPlayers={this.state.ownedPlayers}
                isMobile={this.state.isMobile} 
                createSponsoredTeam={this.createSponsoredTeam}
              />
            </>      
          } />
        </Routes>
      </Router>
      </>
  
    );
  }
  }
  
  export default App;
  