import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated, to } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import { Card } from 'react-bootstrap';
import ReactCardFlip from 'react-card-flip';
import * as formationHelper from '../../helpers/gameplay/formationHelper.js';

const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20;
const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20;

const PlayerCardSpring = ({ cardData, cardSelection, cardFunction, onDropCard, formationType, formationIndex, isMobile }) => {
  const [flipped, setFlipped] = useState(false);
  const [position, setPosition] = useState({ scoreColor: "#FFFFFF" });
  const cardRef = useRef(null);
  const [{ x, y, rotateX, rotateY, rotateZ, scale }, api] = useSpring(() => ({
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    x: 0,
    y: 0,
    config: { mass: 5, tension: 350, friction: 40 },
  }));

  useEffect(() => {
    const position = formationHelper.calcPositionScore(formationType, formationIndex, cardData);
    setPosition(position);
  }, [cardData, formationType, formationIndex]);

  useGesture(
    {
      onDrag: ({ active, offset: [x, y], memo = [0, 0] }) => {
        api({ x, y, scale: active ? 1.1 : 1 });
        return memo;
      },
      onMove: ({ xy: [px, py], dragging }) => {
        if (!dragging) {
          const rect = cardRef.current.getBoundingClientRect();
          api.start({
            rotateX: calcX(py, y.get()),
            rotateY: calcY(px, x.get()),
            scale: 1.1,
          });
        }
      },
      onHover: ({ hovering }) => {
        if (!hovering) {
          api.start({
            rotateX: 0,
            rotateY: 0,
            scale: 1,
          });
        }
      },
    },
    { target: cardRef, eventOptions: { passive: false }}
  );

  return (
    <animated.div
      ref={cardRef}
      style={{
        transform: 'perspective(600px)',
        x,
        y,
        scale: to([scale], (s) => s),
        rotateX,
        rotateY,
        rotateZ,
        cursor: 'grab',
        touchAction: 'none',
      }}
    >
      <ReactCardFlip isFlipped={flipped} flipDirection="horizontal">
        {/* FRONT */}
        <Card
          className={cardSelection !== cardData.tokenId ? "card-front" : "card-front-selected"}
          style={{
            borderColor: position.scoreColor,
            borderWidth: "3px",
            padding: '0%',
            width: 180,
            height: 160,
            touchAction: 'none',
          }}
          onClick={() => {
            cardFunction(cardData.tokenId);
          }}
          data-token-id={cardData.tokenId} // Add data attribute for easier reference
        >
        <div style={{ position: 'relative' }}>
            <Card.Img
                variant="top"
                src={cardData.imgSrc}
                height="100"
                width="200"
                draggable={false}
                onMouseDown={(e) => e.preventDefault()}
            />
            <div style={{ position: 'absolute', top: '5%', left: '5%' }}>
                {position.description}
            </div>
            <div style={{ position: 'absolute', top: '25%', left: '5%' }}
                className='sub-title-mobile'
            >
                {position.positionScore}
            </div>
            </div>
          <Card.Body>
            <Card.Title style={{ textAlign: "center" }}>
              {cardData.title}
            </Card.Title>
          </Card.Body>
        </Card>
        {/* BACK */}
        <Card
          style={{
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.5)',
            padding: '0%',
            width: 180,
            height: 160,
          }}
        >
          <Card.Body>
            <Card.Title>{cardData.title}</Card.Title>
            <table style={{ fontSize: '0.9rem', width: "100%", borderRadius: "15%", textAlign: "center" }}>
              <thead>
                <tr>
                  <th>{cardData.body}</th>
                </tr>
              </thead>
              <tbody>
                {cardData.skillsName.map((row, index) => (
                  <tr key={index}>
                    <td>{row}</td>
                    <td>{cardData.skillsValue[index] === 0 ? "N/A" : cardData.skillsValue[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card.Body>
        </Card>
      </ReactCardFlip>
    </animated.div>
  );
};

export default PlayerCardSpring;
