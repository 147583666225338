import React, { Component } from 'react';
import { Col, Spinner, Row } from 'react-bootstrap';
import openseaLogo from '../../static/openseaLogo.png'
import PlayerCardMarket from '../../components/nft/PlayerCardMarket';
import SignInButton from '../../components/SignInButton';
import * as players from '../../helpers/players.js';


class Explore2D extends Component {
  constructor(props) {
    super(props)
    this.state = {
      optionsList: [],
    }
  }

  
  async componentDidMount(){
    await this.checkSize();
    await this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    // if account prop changes, run init
    if (prevProps.account !== this.props.account) {
       this.init();
    }
  }

  async checkSize() {
    this.setState({
        isMobile : window.innerWidth < 1000
    });
  }

  async init(){
    let ownedPlayers = this.props.ownedPlayers;
    if (ownedPlayers == undefined){
      ownedPlayers = await players.getOwnedPlayers(this.props.account,this.props.CBAddress, this.props.web3);
    }
    this.setState({
      ownedPlayers: ownedPlayers
    })

    var cardsData = await Promise.all(ownedPlayers.map(async (item) => {
      const cardData = players.loadCardDataFull(item, this.props.CBAddress, this.props.web3);
      return cardData;
    }));

    console.log(cardsData)
    this.setState({
      cardsData : cardsData,
      loaded: true
    })
  }

  handleOnChange = async (e) => {
    let allPlayers = this.state.ownedPlayers;
    if (this.state.cardsData != undefined){
      allPlayers = this.state.cardsData;
    }
    // console.log(allPlayers)
    let value = e.target.value;
    if (value.length > 0) {
      let search = await this.arraySearch(allPlayers, value);
      this.setOptionList(search)
    } else {
      this.setOptionList(allPlayers)
    }
  }

  setOptionList(list) {
    let optionList = list.map((v,index)=> (
      <div key={index} onClick={() => console.log(v)}>
            <PlayerCardMarket key={v.tokenId}
              cardData = {v} 
              cardSelection = {this.state.cardSelected}
            />
      </div>
    ));
    // console.log(optionList)
    this.setState({
        optionsList: optionList
    })
  }


  arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase()
    console.log(searchTerm, array)
    //match all items that contain the search term in either title or tokenId
    return array.filter(value => {
      return value.title.toLowerCase().match(new RegExp(searchTerm, 'g')) ||
      value.tokenId.toLowerCase().match(new RegExp(searchTerm, 'g'))
    })
  }

  renderRows(){
    const rows = Math.ceil(this.state.optionsList.length / 3);
    const rowsArray = [];

    for (let i = 0; i < rows; i++) {
      const start = i * 3;
      const end = start + 3;
      const rowItems = this.state.optionsList.slice(start, end);

      const row = (
        <Row key={i} className="justify-content-center" style={{paddingTop:'5%' }}>
          {rowItems.map((item, index) => (
            <Col key={index} md="auto" xs="auto">
              {item}
            </Col>
          ))}
        </Row>
      );

      rowsArray.push(row);
    }

    return rowsArray;
  };

  render(){
    // console.log(this.state.ptfValues)
    console.log(this.state.optionsList)
    return (
      <>
      <div style={ !this.props.isMobile ? {paddingTop: "1%", paddingLeft: "5%"} : {paddingTop: "5%"}}>
        <Row className="justify-content-center" style={{ display: 'flex', justifyContent: 'center' }}>
          <span className='table-title'>
            Explore the Village from the desktop web app!
          </span>
        </Row>
        {this.props.account != undefined ? (
          <>
            {this.state.loaded ? (
              <Row className="justify-content-center">
                <input className="search-input" type="text" name="search" id="search" placeholder="Search name or token id" 
                  onChange={(event) => { this.handleOnChange(event)
                  }}
                  ref={(input) => { this.input = input }}
                />
              </Row>
            ):(
              <Row className="justify-content-center">
                <Spinner animation="border" variant="dark" />
              </Row>
            )}
          </>
        ):(
          <Row className="justify-content-center" style={{ paddingTop: '3%'}}>
            <Col md="auto" xs="auto">
              <SignInButton
                loginButtonFallback={this.props.loginButtonFallback}
                customText={'Sign in to display players'}
                isMobile={this.props.isMobile}
              />
            </Col>
          </Row>
        )}  
        {this.renderRows()}
        <Row className="justify-content-center" style={{paddingTop:'5%' }}>
          <Col md="auto" xs="auto">
            <a href="https://www.opensea.io">
              <img src={openseaLogo} height="200"/>
            </a>
          </Col>
        </Row>
      </div>
      </>
    );
  }
}

export default Explore2D;
