import React from 'react'
import { Col, Row,Modal, Spinner} from 'react-bootstrap'

function TrainingRoomModal(props) {
  // console.log(props)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={props.isMobile ? {maxWidth:'360px'} : null }
      className="rounded-modal"
    >
      <Modal.Header closeButton>
        <>
          {(props.levelUpResult !== "success" && props.levelUpResult !== "failed") && (
            <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
              Waiting for transaction... 
              <span>
                <Spinner animation="border" style={{borderWidth: '4px', height: '1.5rem', width: '1.5rem'}}/>
              </span>
            </Modal.Title>
          )}
          {props.levelUpResult === "success" && (
              <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
                Success!
              </Modal.Title>
          )}
          {props.levelUpResult === "failed" && (
            <Modal.Title id="contained-modal-title-vcenter" style={{color:'black'}}>
              Ah.. unlucky!
            </Modal.Title>
          )}
        </>
      </Modal.Header>
      <Modal.Body>
      {(!props.submittedAttempt && !props.levelUpAttempt && props.levelUpResult !== "failed" && props.levelUpResult !== "success") && (
        <Row className='justify-content-center'>
            <Col md={12} style={{minWidth: '300px', maxWidth: '315px'}}>
                <div>
                  Confirm the transaction in your wallet to start the training!
                </div>
            </Col>
        </Row>
      )}
      {(props.submittedAttempt) && (
        <Row className='justify-content-center'>
            <Col md={12} style={{minWidth: '300px', maxWidth: '315px'}}>
                <div>
                 Your player is training...
                </div>
            </Col>
        </Row>
      )}
      {(props.levelUpAttempt) && (
          <Row className='justify-content-center'>
              <Col md={12} style={{minWidth: '300px', maxWidth: '315px'}}>
                  <div>
                    Your player seems to be performing well in training... quick, confirm the transaction and try level up! 
                  </div>
              </Col>
          </Row>
      )}
      {(!props.levelUpAttempt && props.levelUpResult === "success") && (
          <Row className='justify-content-center'>
              <Col md={12} style={{minWidth: '300px', maxWidth: '315px'}}>
                  <div>
                    Your player has levelled up!
                  </div>
              </Col>
          </Row>
      )}
      {(!props.levelUpAttempt && props.levelUpResult === "failed") && (
          <Row className='justify-content-center'>
              <Col md={12} style={{minWidth: '300px', maxWidth: '315px'}}>
                  <div>
                    Your player has failed to level up... better luck next time!
                  </div>
              </Col>
          </Row>
      )}
      </Modal.Body>
      
    </Modal>
  );
}

export default TrainingRoomModal;
